import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: { input: any; output: any; }
  /** Boolean with more flexible parsing. */
  CustomBoolean: { input: any; output: any; }
  /** Date formatted as yyyy-MM-dd */
  DateOnly: { input: any; output: any; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** JSON value represented as String. */
  JsonStringified: { input: any; output: any; }
  /** Date or `Nullable` (`IS_NULL` or `IS_NOT_NULL`). */
  NullableDate: { input: any; output: any; }
  /** Int or `Nullable` (`IS_NULL` or `IS_NOT_NULL`). */
  NullableInt: { input: any; output: any; }
};

export type Address = {
  __typename?: 'Address';
  address1: Scalars['String']['output'];
  address2?: Maybe<Scalars['String']['output']>;
  bigCommerceAddressId?: Maybe<Scalars['Int']['output']>;
  city: Scalars['String']['output'];
  company: Scalars['String']['output'];
  country: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  postalCode: Scalars['String']['output'];
  stateOrProvince: Scalars['String']['output'];
};

export type AppSettings = {
  __typename?: 'AppSettings';
  defaultLaasFeeToPriceFactor: Scalars['Float']['output'];
  defaultMaintenancePercentage: Scalars['Float']['output'];
  defaultPowerUsageCostExcl: Scalars['Float']['output'];
  defaultPowerUsageCostIncl: Scalars['Float']['output'];
  defaultPowerUsageDays: Array<Scalars['Boolean']['output']>;
  defaultPowerUsageHours: Scalars['Int']['output'];
  defaultSmartFee?: Maybe<Array<SmartFee>>;
  defaultTaxBracket?: Maybe<Array<TaxBracket>>;
  /** Fictional ID to improve caching. */
  id: Scalars['ID']['output'];
  kwHToCO2Factor: Scalars['Float']['output'];
  quotationExpiresInDays: Scalars['Int']['output'];
  treesSavedToCO2Factor: Scalars['Float']['output'];
};

export type Category = {
  __typename?: 'Category';
  /** List of child `Category` items that directly belong to this `Category`. */
  children: Array<Category>;
  createdAt: Scalars['DateTime']['output'];
  defaultProductSort: Scalars['String']['output'];
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  layoutFile: Scalars['String']['output'];
  metaDescription: Scalars['String']['output'];
  metaKeywords: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  pageTitle: Scalars['String']['output'];
  /** Get the parent `Category`. */
  parent?: Maybe<Category>;
  /** Get the products in this `Category`. */
  products: Array<Product>;
  searchKeywords: Array<Scalars['String']['output']>;
  sortOrder: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type CategoryCondition = {
  parentId?: InputMaybe<Scalars['NullableInt']['input']>;
};

export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** A list of edges which contains the `Category` and cursor to aid in pagination. */
  edges: Array<CategoryEdge>;
  /** A list of `Category` objects. */
  nodes: Array<Category>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Category` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Category` edge in the connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The `Category` at the end of the edge. */
  node: Category;
};

/** The method to use when ordering `Category`. */
export enum CategoryOrderBy {
  /** Order by `name` in *asc* order. */
  NameAsc = 'NAME_ASC',
  /** Order by `name` in *desc* order. */
  NameDesc = 'NAME_DESC',
  /** Order by `id` in *asc* order. */
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  /** Order by `id` in *desc* order. */
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  /** Order by `sortOrder` in *asc* order __(default)__. */
  SortOrderAsc = 'SORT_ORDER_ASC',
  /** Order by `sortOrder` in *desc* order. */
  SortOrderDesc = 'SORT_ORDER_DESC'
}

/** The specific change recorded for a `Quotation`. */
export enum ChangelogChange {
  AddedRoom = 'ADDED_ROOM',
  AddedServiceCosts = 'ADDED_SERVICE_COSTS',
  ChangedContact = 'CHANGED_CONTACT',
  ChangedDescription = 'CHANGED_DESCRIPTION',
  ChangedDiscount = 'CHANGED_DISCOUNT',
  ChangedInternalNotes = 'CHANGED_INTERNAL_NOTES',
  ChangedIntervals = 'CHANGED_INTERVALS',
  ChangedLaasToPriceFactor = 'CHANGED_LAAS_TO_PRICE_FACTOR',
  ChangedPaymentType = 'CHANGED_PAYMENT_TYPE',
  ChangedPowerCost = 'CHANGED_POWER_COST',
  ChangedPowerUsage = 'CHANGED_POWER_USAGE',
  ChangedProductLineItems = 'CHANGED_PRODUCT_LINE_ITEMS',
  ChangedProjectLocatie = 'CHANGED_PROJECT_LOCATIE',
  ChangedReference = 'CHANGED_REFERENCE',
  ChangedRoom = 'CHANGED_ROOM',
  ChangedSignature = 'CHANGED_SIGNATURE',
  ChangedStatusToLost = 'CHANGED_STATUS_TO_LOST',
  ChangedStatusToOpen = 'CHANGED_STATUS_TO_OPEN',
  ChangedStatusToWon = 'CHANGED_STATUS_TO_WON',
  ChangedTargetAudience = 'CHANGED_TARGET_AUDIENCE',
  ChangedTotalMonthlyLaasChargeOverwrite = 'CHANGED_TOTAL_MONTHLY_LAAS_CHARGE_OVERWRITE',
  Cloned = 'CLONED',
  Created = 'CREATED',
  Ready = 'READY',
  ReadyResend = 'READY_RESEND',
  RemovedRoom = 'REMOVED_ROOM',
  RemovedServiceCosts = 'REMOVED_SERVICE_COSTS',
  RemovedSignature = 'REMOVED_SIGNATURE',
  Transferred = 'TRANSFERRED'
}

export type Contact = {
  __typename?: 'Contact';
  bigCommerceCustomerId?: Maybe<Scalars['Int']['output']>;
  /** Billing `Address` of the `Contact`. */
  billingAddress?: Maybe<Address>;
  cocNumber?: Maybe<Scalars['String']['output']>;
  companyName?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customerNumber?: Maybe<Scalars['Int']['output']>;
  exactId?: Maybe<Scalars['String']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  /** Are the Shipping and Billing `Address` the same for this `Contact`. */
  hasSameBillingAndShippingAddress: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  invoiceEmail?: Maybe<Scalars['String']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  lastName: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  quotationEmail?: Maybe<Scalars['String']['output']>;
  /** Shipping `Address` of the `Contact`. */
  shippingAddress?: Maybe<Address>;
  updatedAt: Scalars['DateTime']['output'];
  websites?: Maybe<Array<Scalars['String']['output']>>;
};

export type ContactConnection = {
  __typename?: 'ContactConnection';
  /** A list of edges which contains the `Contact` and cursor to aid in pagination. */
  edges: Array<ContactEdge>;
  /** A list of `Contact` objects. */
  nodes: Array<Contact>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Contact` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Contact` edge in the connection. */
export type ContactEdge = {
  __typename?: 'ContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The `Contact` at the end of the edge. */
  node: Contact;
};

/** The method to use when ordering `Contact`. */
export enum ContactOrderBy {
  /** Order by `firstName` in *asc* order. */
  FirstNameAsc = 'FIRST_NAME_ASC',
  /** Order by `firstName` in *desc* order. */
  FirstNameDesc = 'FIRST_NAME_DESC',
  /** Order by `id` in *asc* order __(default)__. */
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  /** Order by `id` in *desc* order. */
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** The status of a `User` at the moment of requesting the value. */
export enum CurrentUserStatus {
  /** Regular active `User`. */
  Active = 'ACTIVE',
  /** At the time of requesting the value, the `User` was blocked. */
  Blocked = 'BLOCKED',
  /** Email invite has been sent, but the onboarding has not been finished. */
  Invited = 'INVITED'
}

/** The status a `Quotation` can have. */
export enum DealStatus {
  /** Quotation is lost and can no longer be edited. */
  Lost = 'LOST',
  /** Quotation is created and actively being edited */
  Open = 'OPEN',
  /** Quotation is ready for the customer to approve, setting a Quotation from OPEN to READY will trigger a Zapier zap which will send an email. The Quotation can still be edited. */
  Ready = 'READY',
  /** Quotation is won and can no longer be edited. */
  Won = 'WON'
}

export type DeletedContact = {
  __typename?: 'DeletedContact';
  deletedContactId: Scalars['ID']['output'];
};

export type DeletedQuotation = {
  __typename?: 'DeletedQuotation';
  deletedQuotationId: Scalars['ID']['output'];
};

export type DeletedQuotationLineItem = {
  __typename?: 'DeletedQuotationLineItem';
  deletedQuotationLineItemId: Scalars['ID']['output'];
  quotationId: Scalars['ID']['output'];
  roomId: Scalars['ID']['output'];
};

export type DeletedQuotationRoom = {
  __typename?: 'DeletedQuotationRoom';
  deletedQuotationRoomId: Scalars['ID']['output'];
  quotationId: Scalars['ID']['output'];
};

export type InputAddContactToQuotation = {
  contactId: Scalars['ID']['input'];
  quotationId: Scalars['ID']['input'];
};

export type InputBlockUser = {
  /** Optionally provide a Date/ timestamp when the `User` should be blocked. For blocking only, cannot be combined with `unblock`. */
  blockedAt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Set to `true` to unblock the `User`. Cannot be combined with the `blockedAt` field. */
  unblock?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['ID']['input'];
};

export type InputCategory = {
  categoryId: Scalars['ID']['input'];
};

export type InputCloneQuotation = {
  /** The id of the `Quotation` that should be cloned. */
  quotationId: Scalars['String']['input'];
};

export type InputCloneQuotationLineItem = {
  quotationLineItemId: Scalars['ID']['input'];
};

export type InputContact = {
  contactId: Scalars['ID']['input'];
};

export type InputContactByName = {
  name: Scalars['String']['input'];
};

export type InputCreateContact = {
  billingAddress?: InputMaybe<InputCreateContactAddress>;
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  exactId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  /** The `billingAddress` will overwrite the `shippingAddress` when both are provided and this value is set to `true`. */
  hasDifferentBillingAndShippingAddress?: Scalars['Boolean']['input'];
  invoiceEmail?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  quotationEmail?: InputMaybe<Scalars['String']['input']>;
  quotationId?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<InputCreateContactAddress>;
  websites?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InputCreateContactAddress = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  stateOrProvince: Scalars['String']['input'];
};

export type InputCreateQuotation = {
  /** Directly link the newly created `Quotation` to an existing `Contact`. */
  customerId?: InputMaybe<Scalars['String']['input']>;
};

export type InputCreateQuotationRoom = {
  name: Scalars['String']['input'];
  powerUsageDays?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  powerUsageHours?: InputMaybe<Scalars['Int']['input']>;
  /** Directly link the newly created `QuotationRoom` to an existing `Quotation`. */
  quotationId: Scalars['String']['input'];
  roomHeight: Scalars['Float']['input'];
  sensorOnTimePercentage?: InputMaybe<Scalars['Float']['input']>;
  verticallyAccessible: Scalars['Boolean']['input'];
};

export type InputCreateUser = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  commissionPercentage?: InputMaybe<Scalars['Float']['input']>;
  companynameowner?: InputMaybe<Scalars['String']['input']>;
  email: Scalars['String']['input'];
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  role?: UserRole;
};

export type InputDeleteContact = {
  contactId: Scalars['ID']['input'];
};

export type InputDeleteProduct = {
  productId: Scalars['Int']['input'];
};

export type InputDeleteContactFromQuotation = {
  quotationId: Scalars['ID']['input'];
};

export type InputDeleteQuotation = {
  quotationId: Scalars['ID']['input'];
};

export type InputDeleteQuotationLineItem = {
  quotationLineItemId: Scalars['ID']['input'];
};

export type InputDeleteQuotationRoom = {
  quotationRoomId: Scalars['String']['input'];
};

export type InputDetailedKvkSearch = {
  /** Do a detailed search for a company by kvk-number, possibly via the pay-by-request `basisprofiel` API. */
  kvkNumber: Scalars['String']['input'];
};

export type InputGeneratePdf = {
  quotationId: Scalars['String']['input'];
};

export type InputInvitedUser = {
  inviteToken: Scalars['String']['input'];
};

export type InputKvkSearch = {
  /** Search a company by name or kvk-number. Max 32 characters allowed. */
  search: Scalars['String']['input'];
};

export type InputMonthlyAnalytics = {
  /** Array of first-day-of-the-month, with a max of today */
  months: Array<Scalars['DateOnly']['input']>;
  userId: Scalars['ID']['input'];
};

export type InputMoveLineItemToRoom = {
  lineItemId: Scalars['ID']['input'];
  newRoomId: Scalars['ID']['input'];
};

export type InputMyMonthlyAnalytics = {
  /** Array of first-day-of-the-month, with a max of today */
  months: Array<Scalars['DateOnly']['input']>;
};

export type InputProduct = {
  productId: Scalars['ID']['input'];
};

export type InputQuotation = {
  quotationId: Scalars['ID']['input'];
};

export type InputQuotationRoom = {
  quotationRoomId: Scalars['ID']['input'];
};

export type InputRegisterUser = {
  companyNameOwner?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  inviteToken: Scalars['String']['input'];
  lastName?: InputMaybe<Scalars['String']['input']>;
  /**
   * Password must be at least 6 characters long and contain at least:
   *   * 1 number (0-9)
   *   * 1 uppercase letter (A-Z)
   *   * 1 lowercase letter (a-z)
   *   * 1 non-alpha numeric number
   */
  password: Scalars['String']['input'];
};

export type InputResendInvite = {
  userId: Scalars['ID']['input'];
};

export type InputResendReadyQuotation = {
  quotationId: Scalars['ID']['input'];
};

export type InputResetPassword = {
  email: Scalars['String']['input'];
  /**
   * Password must be at least 6 characters long and contain at least:
   *   * 1 number (0-9)
   *   * 1 uppercase letter (A-Z)
   *   * 1 lowercase letter (a-z)
   *   * 1 non-alpha numeric number
   */
  newPassword: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};

export type InputSendResetPasswordToken = {
  email: Scalars['String']['input'];
};

export type InputSetAppSettings = {
  defaultLaasFeeToPriceFactor?: InputMaybe<Scalars['Float']['input']>;
  defaultMaintenancePercentage?: InputMaybe<Scalars['Float']['input']>;
  defaultPowerUsageCostExcl?: InputMaybe<Scalars['Float']['input']>;
  defaultPowerUsageCostIncl?: InputMaybe<Scalars['Float']['input']>;
  defaultPowerUsageDays?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  defaultPowerUsageHours?: InputMaybe<Scalars['Int']['input']>;
  defaultSmartFee?: InputMaybe<Array<InputSetAppSettingsSmartFee>>;
  defaultTaxBracket?: InputMaybe<Array<InputSetAppSettingsTaxBracket>>;
  kwHToCO2Factor?: InputMaybe<Scalars['Float']['input']>;
  quotationExpiresInDays?: InputMaybe<Scalars['Int']['input']>;
  treesSavedToCO2Factor?: InputMaybe<Scalars['Float']['input']>;
};

export type InputSetAppSettingsSmartFee = {
  minQuantity: Scalars['Int']['input'];
  monthlySmartFeePerFixture: Scalars['Float']['input'];
};

export type InputSetAppSettingsTaxBracket = {
  cost_per_kwh: Scalars['Float']['input'];
  fixed_cost: Scalars['Float']['input'];
  min_usage_kwh: Scalars['Int']['input'];
};

export type InputSetQuotationLineItem = {
  /** The assemblyCost for `QuotationLineItem`? */
  assemblyCost?: InputMaybe<Scalars['Float']['input']>;
  ceilingType?: InputMaybe<Scalars['String']['input']>;
  connectionType?: InputMaybe<Scalars['String']['input']>;
  /** Choices made in the ProductFilter to get to this `QuotationLineItem` as stringified JSON. */
  filterChoices?: InputMaybe<Scalars['JsonStringified']['input']>;
  /** Option to overwrite the `Product` name. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Possibility to link a new line-item to an 'old' `Product`. */
  oldProductId?: InputMaybe<Scalars['Int']['input']>;
  /** Option to manually overwrite or set the old `Product` name. */
  oldProductName?: InputMaybe<Scalars['String']['input']>;
  /** When retrofitting a 'new' `Product` might replace multiple 'old' `Products`. */
  oldProductQuantity?: InputMaybe<Scalars['Int']['input']>;
  /** Option to manually overwrite or set the old `Product` wattage. Set to `0` to mark the `Product` as a 'new' `Product` that is not replacing an existing `Product`. */
  oldProductWattage?: InputMaybe<Scalars['Int']['input']>;
  otherComments?: InputMaybe<Scalars['String']['input']>;
  outsideSize?: InputMaybe<Scalars['String']['input']>;
  /** Option to overwrite the `QuotationRoom` and/ or `Quotation` level power usage days specifically for this line-item. */
  powerUsageDays?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Option to overwrite the `QuotationRoom` and/ or `Quotation` level power usage hours specifically for this line-item. */
  powerUsageHours?: InputMaybe<Scalars['Int']['input']>;
  /** The related product that is selected. Required for a new line item. */
  productId?: InputMaybe<Scalars['Int']['input']>;
  /** The related `ProductVariant` that might be selected. */
  productVariantId?: InputMaybe<Scalars['Int']['input']>;
  /** The label of the product variant selected for `Product`. */
  productVariantLabel?: InputMaybe<Scalars['String']['input']>;
  /** The number of line-items that is ordered. Note that this also multiplies the number of replaced 'old' `Products`. */
  quantity?: InputMaybe<Scalars['Int']['input']>;
  quotationLineItemId?: InputMaybe<Scalars['ID']['input']>;
  /** Provide a parent `QuotationRoom` id. When the `QuotationLineItem` already exists, it will be moved to the selected `QuotationRoom`. */
  quotationRoomId?: InputMaybe<Scalars['ID']['input']>;
  /** The service cost for `QuotationLineItem`? */
  serviceCost?: InputMaybe<Scalars['Float']['input']>;
  /** Option to manually overwrite `Product` wattage. */
  wattage?: InputMaybe<Scalars['Int']['input']>;
};

export type InputTransferQuotation = {
  /** It should be a "User.id".We use newAccountManagerId to avoid complication */
  newAccountManagerId: Scalars['ID']['input'];
  quotationId: Scalars['ID']['input'];
};

export type InputUpdateContact = {
  billingAddress?: InputMaybe<InputUpdateContactAddress>;
  cocNumber?: InputMaybe<Scalars['String']['input']>;
  companyName?: InputMaybe<Scalars['String']['input']>;
  contactId: Scalars['ID']['input'];
  exactId?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** The `billingAddress` will overwrite the `shippingAddress` when both are provided and this value is set to `true`. */
  hasDifferentBillingAndShippingAddress?: Scalars['Boolean']['input'];
  invoiceEmail?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  quotationEmail?: InputMaybe<Scalars['String']['input']>;
  shippingAddress?: InputMaybe<InputUpdateContactAddress>;
  websites?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type InputUpdateProduct = {
  name?: InputMaybe<Scalars['String']['input']>;
  sku?: InputMaybe<Scalars['String']['input']>;
  productId: Scalars['ID']['input'];
  brandId: Scalars['Int']['input'];
  description?: InputMaybe<Scalars['String']['input']>;
  weight?: InputMaybe<Scalars['Float']['input']>;
  isFreeShipping?: Scalars['Boolean']['input'];
  type?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

export type InputUpdateContactAddress = {
  address1: Scalars['String']['input'];
  address2?: InputMaybe<Scalars['String']['input']>;
  city: Scalars['String']['input'];
  company?: InputMaybe<Scalars['String']['input']>;
  country: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  postalCode: Scalars['String']['input'];
  stateOrProvince: Scalars['String']['input'];
};

export type InputUpdateMyPassword = {
  newPassword: Scalars['String']['input'];
  oldPassword: Scalars['String']['input'];
};

export type InputUpdateMyUser = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  companyNameOwner?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  setRoleToAccountManager?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InputUpdateQuotationDiscount = {
  discountFixed?: InputMaybe<Scalars['Float']['input']>;
  discountPercentage?: InputMaybe<Scalars['Float']['input']>;
  quotationId: Scalars['ID']['input'];
};

export type InputUpdateQuotationInternalNotes = {
  internalKostenPlaatsNummer: Scalars['String']['input'];
  internalNotes: Scalars['String']['input'];
  quotationId: Scalars['ID']['input'];
};

export type InputUpdateQuotationIntervals = {
  /** Percentage betalingstermijn 3. */
  betalingsPercentageDrie: Scalars['Int']['input'];
  /** Percentage betalingstermijn 1. */
  betalingsPercentageEen: Scalars['Int']['input'];
  /** Percentage betalingstermijn 2. */
  betalingsPercentageTwee: Scalars['Int']['input'];
  quotationId: Scalars['ID']['input'];
  /** The desired interval in months for the service cost payments. */
  serviceCostInterval: Scalars['Int']['input'];
  /** The desired interval in months for the smart fee payments. */
  smartFeeInterval: Scalars['Int']['input'];
};

export type InputUpdateQuotationLaasDiscount = {
  quotationId: Scalars['ID']['input'];
  /** The desired overwrite value for the totalMonthlyLaasCharge. Set to `null` to clear. */
  totalMonthlyLaasChargeOverwrite?: InputMaybe<Scalars['Float']['input']>;
};

export type InputUpdateQuotationPaymentType = {
  /** Admins are allowed to overwrite the factor on a per Quotation basis. */
  laasToPriceFactor?: InputMaybe<Scalars['Float']['input']>;
  paymentType: PaymentType;
  quotationId: Scalars['ID']['input'];
};

export type InputUpdateQuotationPowerUsageCost = {
  isNewLocation?: InputMaybe<Scalars['Boolean']['input']>;
  powerCostExcl?: InputMaybe<Scalars['Float']['input']>;
  powerCostIncl?: InputMaybe<Scalars['Float']['input']>;
  powerUsageDays?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  powerUsageHours?: InputMaybe<Scalars['Int']['input']>;
  quotationId: Scalars['ID']['input'];
};

export type InputUpdateQuotationProjectLocatieDescription = {
  projectLocatie: Scalars['String']['input'];
  quotationId: Scalars['ID']['input'];
};

export type InputUpdateQuotationReferenceDescription = {
  description: Scalars['String']['input'];
  quotationId: Scalars['ID']['input'];
  reference: Scalars['String']['input'];
};

export type InputUpdateQuotationRoom = {
  name?: InputMaybe<Scalars['String']['input']>;
  powerUsageDays?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  powerUsageHours?: InputMaybe<Scalars['Int']['input']>;
  quotationRoomId: Scalars['String']['input'];
  roomHeight?: InputMaybe<Scalars['Float']['input']>;
  sensorOnTimePercentage?: InputMaybe<Scalars['Float']['input']>;
  verticallyAccessible?: InputMaybe<Scalars['Boolean']['input']>;
};

export type InputUpdateQuotationSignature = {
  quotationId: Scalars['ID']['input'];
  signature: Scalars['String']['input'];
};

export type InputUpdateQuotationStatus = {
  quotationId: Scalars['ID']['input'];
  status: Scalars['String']['input'];
};

export type InputUpdateQuotationTargetAudience = {
  quotationId: Scalars['ID']['input'];
  targetAudience: Scalars['String']['input'];
};

export type InputUpdateUser = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  commissionPercentage?: InputMaybe<Scalars['Float']['input']>;
  companyNameOwner?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  /**
   * Password must be at least 6 characters long and contain at least:
   *   * 1 number (0-9)
   *   * 1 uppercase letter (A-Z)
   *   * 1 lowercase letter (a-z)
   *   * 1 non-alpha numeric number
   */
  password?: InputMaybe<Scalars['String']['input']>;
  phoneNumbers?: InputMaybe<Array<Scalars['String']['input']>>;
  role?: InputMaybe<UserRole>;
  userId: Scalars['ID']['input'];
};

export type InputUser = {
  userId: Scalars['ID']['input'];
};

export type InputUserByName = {
  /** Query to search the User by, searches in firstName and lastName. */
  name: Scalars['String']['input'];
};

export type InputValidateResetPasswordToken = {
  email: Scalars['String']['input'];
  resetPasswordToken: Scalars['String']['input'];
};

export type InvitedUser = {
  __typename?: 'InvitedUser';
  companyNameOwner?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  invitedByFullName: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role: UserRole;
};

export type KvkCompany = {
  __typename?: 'KvkCompany';
  establishmentNumber?: Maybe<Scalars['String']['output']>;
  formerName?: Maybe<Scalars['String']['output']>;
  houseNumber?: Maybe<Scalars['Float']['output']>;
  houseNumberAddition?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['String']['output']>;
  kvkNumber: Scalars['String']['output'];
  place?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  rsin?: Maybe<Scalars['String']['output']>;
  streetName?: Maybe<Scalars['String']['output']>;
  tradeName?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

export type MonthlyAnalytics = {
  __typename?: 'MonthlyAnalytics';
  accessoryCount: Scalars['Int']['output'];
  /** Null when no quotations were lost in this month */
  averageLostLeadTimeDays?: Maybe<Scalars['Float']['output']>;
  /** Null when no quotations were won in this month */
  averageWonLeadTimeDays?: Maybe<Scalars['Float']['output']>;
  /** Total commission for this month in Euros */
  commission: Scalars['Float']['output'];
  /** Null when no quotations were won in this month */
  energyRanking?: Maybe<Scalars['Int']['output']>;
  energySavingsPerYear: Scalars['Float']['output'];
  fixtureCount: Scalars['Int']['output'];
  lineItemCount: Scalars['Int']['output'];
  lostCount: Scalars['Int']['output'];
  /** First day of the month for the analytics */
  month: Scalars['DateOnly']['output'];
  monthlySmartFee: Scalars['Float']['output'];
  openCount: Scalars['Int']['output'];
  productCount: Scalars['Int']['output'];
  readyCount: Scalars['Int']['output'];
  roomCount: Scalars['Int']['output'];
  totalAssemblyCost: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  user?: Maybe<User>;
  usersWithSalesCount: Scalars['Int']['output'];
  wonCount: Scalars['Int']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Update a `Quotation`'s discount */
  UpdateQuotationSignature: Quotation;
  /** Add a `Contact to a `Quotation` */
  addContactToQuotation: Quotation;
  /** Block or unblock a `User` as an `ADMIN` */
  blockUser: User;
  /** Clone `Quotation` and set status to `OPEN`. */
  cloneQuotation: Quotation;
  /** Clone a line-item. */
  cloneQuotationLineItem: Quotation;
  /** Create a `Contact` */
  createContact: Contact;
  /** Create an empty `Quotation` and optionally link it to a `Contact` */
  createQuotation: Quotation;
  /** Create a `QuotationRoom` and link it to a `Quotation` */
  createQuotationRoom: QuotationRoom;
  /** Create a `User` */
  createUser: User;
  /** Delete a `Contact` */
  deleteContact: DeletedContact;
  /** Delete a `Contact from  a `Quotation` */
  deleteContactFromQuotation: Quotation;
  /** Delete `Quotation` */
  deleteQuotation: DeletedQuotation;
  /** Remove a line-item. */
  deleteQuotationLineItem: DeletedQuotationLineItem;
  /** Delete a `QuotationRoom` */
  deleteQuotationRoom: DeletedQuotationRoom;
  /** Generate a new PDF for `Quotation` */
  generatePdf: Quotation;
  /** Move a `QuotationLineItem` to an existing `QuotationRoom` within the same `Quotation`. */
  moveLineItemToRoom: Quotation;
  /** Register a `User` by providing an `inviteToken` */
  registerUser: InvitedUser;
  /** Resend the email invite to an invited `User` as an `ADMIN` */
  resendInvite: User;
  /** Resend a `Quotation` that has a status of `READY`. */
  resendReadyQuotation: Quotation;
  /** Reset a `User` password by providing a `resetPasswordToken` */
  resetPassword: User;
  /** Send a `resetPasswordToken` to `User`'s email address */
  sendResetPasswordToken: SendResetPasswordTokenResponse;
  /** Set new AppSettings */
  setAppSettings: AppSettings;
  /** Create or update a line-item (in a `QuotationRoom)`. */
  setQuotationLineItem: QuotationLineItem;
  /** Transfer a `Quotation to another `User`. The `searchUserByName` query can be used to know who to transfer it to. */
  transferQuotation: Quotation;
  /** Update a `Contact` */
  updateContact: Contact;
  /** Update the signed in `User`'s password */
  updateMyPassword: User;
  /** Update the signed in `User` */
  updateMyUser: User;
  /** Update a `Quotation`'s discount */
  updateQuotationDiscount: Quotation;
  /** Update a `Quotation`'s internal notes */
  updateQuotationInternalNotes: Quotation;
  /** Update a `Quotation`'s serviceCost or smartFee intervals. */
  updateQuotationIntervals: Quotation;
  /** Overwrite a `Quotation`'s monthly LaaS charge. */
  updateQuotationLaasDiscount: Quotation;
  /** Update a `Quotation`'s payment type. */
  updateQuotationPaymentType: Quotation;
  /** Update a `Quotation`'s power usage and/ or its power cost */
  updateQuotationPowerUsageCost: Quotation;
  /** Update a `Quotation` */
  updateQuotationProjectLocatie: Quotation;
  /** Update a `Quotation` */
  updateQuotationReferenceAndDescription: Quotation;
  /** Update a `QuotationRoom` */
  updateQuotationRoom: QuotationRoom;
  /** Update a `Quotation`'s status to 'won' or 'lost' */
  updateQuotationStatus: Quotation;
  /** Update the `Quotation`'s target audience. */
  updateQuotationTargetAudience: Quotation;
  /** Update a `User` as an `ADMIN` */
  updateUser: User;
};


export type MutationUpdateQuotationSignatureArgs = {
  input: InputUpdateQuotationSignature;
};


export type MutationAddContactToQuotationArgs = {
  input: InputAddContactToQuotation;
};


export type MutationBlockUserArgs = {
  input: InputBlockUser;
};


export type MutationCloneQuotationArgs = {
  input: InputCloneQuotation;
};


export type MutationCloneQuotationLineItemArgs = {
  input: InputCloneQuotationLineItem;
};


export type MutationCreateContactArgs = {
  input: InputCreateContact;
};


export type MutationCreateQuotationArgs = {
  input: InputCreateQuotation;
};


export type MutationCreateQuotationRoomArgs = {
  input: InputCreateQuotationRoom;
};


export type MutationCreateUserArgs = {
  input: InputCreateUser;
};


export type MutationDeleteContactArgs = {
  input: InputDeleteContact;
};

export type MutationDeleteProductArgs = {
  input: InputDeleteProduct;
};

export type MutationDeleteContactFromQuotationArgs = {
  input: InputDeleteContactFromQuotation;
};


export type MutationDeleteQuotationArgs = {
  input: InputDeleteQuotation;
};


export type MutationDeleteQuotationLineItemArgs = {
  input: InputDeleteQuotationLineItem;
};


export type MutationDeleteQuotationRoomArgs = {
  input: InputDeleteQuotationRoom;
};


export type MutationGeneratePdfArgs = {
  input: InputGeneratePdf;
};


export type MutationMoveLineItemToRoomArgs = {
  input: InputMoveLineItemToRoom;
};


export type MutationRegisterUserArgs = {
  input: InputRegisterUser;
};


export type MutationResendInviteArgs = {
  input: InputResendInvite;
};


export type MutationResendReadyQuotationArgs = {
  input: InputResendReadyQuotation;
};


export type MutationResetPasswordArgs = {
  input: InputResetPassword;
};


export type MutationSendResetPasswordTokenArgs = {
  input: InputSendResetPasswordToken;
};


export type MutationSetAppSettingsArgs = {
  input: InputSetAppSettings;
};


export type MutationSetQuotationLineItemArgs = {
  input: InputSetQuotationLineItem;
};


export type MutationTransferQuotationArgs = {
  input: InputTransferQuotation;
};


export type MutationUpdateContactArgs = {
  input: InputUpdateContact;
};

export type MutationUpdateProductArgs = {
  input: InputUpdateProduct;
};


export type MutationUpdateMyPasswordArgs = {
  input: InputUpdateMyPassword;
};


export type MutationUpdateMyUserArgs = {
  input: InputUpdateMyUser;
};


export type MutationUpdateQuotationDiscountArgs = {
  input: InputUpdateQuotationDiscount;
};


export type MutationUpdateQuotationInternalNotesArgs = {
  input: InputUpdateQuotationInternalNotes;
};


export type MutationUpdateQuotationIntervalsArgs = {
  input: InputUpdateQuotationIntervals;
};


export type MutationUpdateQuotationLaasDiscountArgs = {
  input: InputUpdateQuotationLaasDiscount;
};


export type MutationUpdateQuotationPaymentTypeArgs = {
  input: InputUpdateQuotationPaymentType;
};


export type MutationUpdateQuotationPowerUsageCostArgs = {
  input: InputUpdateQuotationPowerUsageCost;
};


export type MutationUpdateQuotationProjectLocatieArgs = {
  input: InputUpdateQuotationProjectLocatieDescription;
};


export type MutationUpdateQuotationReferenceAndDescriptionArgs = {
  input: InputUpdateQuotationReferenceDescription;
};


export type MutationUpdateQuotationRoomArgs = {
  input: InputUpdateQuotationRoom;
};


export type MutationUpdateQuotationStatusArgs = {
  input: InputUpdateQuotationStatus;
};


export type MutationUpdateQuotationTargetAudienceArgs = {
  input: InputUpdateQuotationTargetAudience;
};


export type MutationUpdateUserArgs = {
  input: InputUpdateUser;
};

export type OptionValue = {
  __typename?: 'OptionValue';
  id: Scalars['ID']['output'];
  label?: Maybe<Scalars['String']['output']>;
  optionDisplayName?: Maybe<Scalars['String']['output']>;
  optionId: Scalars['ID']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']['output']>;
};

/** The payment type a `Quotation` can have. */
export enum PaymentType {
  /** Sell the quotation directly (i.e. customer pays money up front - regular sale). */
  Full = 'FULL',
  /** Sell Lights as a Service (i.e. customer pays a monthly fee - no money up front). */
  Laas = 'LAAS'
}

export type Product = {
  __typename?: 'Product';
  availability: Scalars['String']['output'];
  availabilityDescription: Scalars['String']['output'];
  baseVariant?: Maybe<ProductVariant>;
  binPickingNumber: Scalars['String']['output'];
  brandId: Scalars['Float']['output'];
  calculatedPrice: Scalars['Float']['output'];
  /** Get the categories this `Product` is in. */
  categories: Array<Category>;
  condition: Scalars['String']['output'];
  costPrice: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Get the `ProductCustomField`s for this `Product`. */
  customFields: ProductCustomField;
  dateCreated: Scalars['DateTime']['output'];
  dateModified: Scalars['DateTime']['output'];
  depth: Scalars['Float']['output'];
  description: Scalars['String']['output'];
  fixedCostShippingPrice: Scalars['Float']['output'];
  gtin: Scalars['String']['output'];
  height: Scalars['Float']['output'];
  id: Scalars['Int']['output'];
  inventoryLevel: Scalars['Float']['output'];
  inventoryTracking: Scalars['String']['output'];
  inventoryWarningLevel: Scalars['Float']['output'];
  isConditionShown: Scalars['Boolean']['output'];
  isFeatured: Scalars['Boolean']['output'];
  isFreeShipping: Scalars['Boolean']['output'];
  isPreorderOnly: Scalars['Boolean']['output'];
  isPriceHidden: Scalars['Boolean']['output'];
  isSensor?: Maybe<Scalars['Boolean']['output']>;
  isVisible: Scalars['Boolean']['output'];
  lastSyncedAt: Scalars['DateTime']['output'];
  layoutFile: Scalars['String']['output'];
  mapPrice: Scalars['Float']['output'];
  metaDescription: Scalars['String']['output'];
  metaKeywords: Array<Scalars['String']['output']>;
  mpn: Scalars['String']['output'];
  name: Scalars['String']['output'];
  openGraphDescription: Scalars['String']['output'];
  openGraphTitle: Scalars['String']['output'];
  openGraphUseImage: Scalars['Boolean']['output'];
  openGraphUseMetaDescription: Scalars['Boolean']['output'];
  openGraphUseProductName: Scalars['Boolean']['output'];
  orderQuantityMaximum: Scalars['Float']['output'];
  orderQuantityMinimum: Scalars['Float']['output'];
  pageTitle: Scalars['String']['output'];
  preorderMessage: Scalars['String']['output'];
  preorderReleaseDate: Scalars['DateTime']['output'];
  price: Scalars['Float']['output'];
  priceHiddenLabel: Scalars['String']['output'];
  productTaxCode: Scalars['String']['output'];
  /** Get the `Product`s that are related to this `Product`. */
  relatedProducts: Array<Product>;
  retailPrice: Scalars['Float']['output'];
  salePrice: Scalars['Float']['output'];
  searchKeywords: Array<Scalars['String']['output']>;
  sku: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
  taxClassId: Scalars['Float']['output'];
  /** Get the `ProductThumbnail` for this `Product`. */
  thumbnail?: Maybe<ProductThumbnail>;
  type: Scalars['String']['output'];
  upc: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  /** Get the `ProductVariant`s of this `Product`. */
  variants: Array<ProductVariant>;
  warranty: Scalars['String']['output'];
  weight: Scalars['Float']['output'];
  width: Scalars['Float']['output'];
};

export type ProductConnection = {
  __typename?: 'ProductConnection';
  /** A list of edges which contains the `Product` and cursor to aid in pagination. */
  edges: Array<ProductEdge>;
  /** A list of `Product` objects. */
  nodes: Array<Product>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Product` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

export type ProductCustomField = {
  __typename?: 'ProductCustomField';
  /** Price to let Firefly install the product in Euros. */
  assemblyCost?: Maybe<Scalars['Float']['output']>;
  /** Beam angle in degrees */
  beamAngle?: Maybe<Scalars['Int']['output']>;
  /** Beam angle description */
  beamAngleText?: Maybe<Scalars['String']['output']>;
  /** Diameter in mm. */
  diameter?: Maybe<Scalars['Int']['output']>;
  /** Color of the fixture of the product. */
  fixtureColor?: Maybe<Scalars['String']['output']>;
  /** Does the product require a starter */
  hasStarter?: Maybe<Scalars['Boolean']['output']>;
  /** Height in mm. */
  height?: Maybe<Scalars['Int']['output']>;
  /** Is this product eligible for the EIA subsidy? */
  isEligibleForEia?: Maybe<Scalars['Boolean']['output']>;
  /** Length in mm. */
  length?: Maybe<Scalars['Int']['output']>;
  /** Light intensity in lumen */
  lightIntensity?: Maybe<Scalars['Int']['output']>;
  /** Light intensity qualifier */
  lightIntensityText?: Maybe<Scalars['String']['output']>;
  /** Is this a retro-fit solution? i.e. does it require an existing fixture? */
  retrofit: Scalars['Boolean']['output'];
  /** How many of this new, retro-fit product are required for one existing fixture? */
  retrofitQuantity?: Maybe<Scalars['Int']['output']>;
  /** Series the product is in */
  series?: Maybe<Scalars['String']['output']>;
  /** Price for monthly service by Firefly for the product in Euros. */
  serviceCost?: Maybe<Scalars['Float']['output']>;
  /** Light socket of the product */
  socket?: Maybe<Scalars['String']['output']>;
  /** The heights this product can be placed at. */
  suspensionHeights?: Maybe<Array<Scalars['String']['output']>>;
  /** The tiltability of the product. */
  tiltability?: Maybe<Tiltability>;
  /** Tube type */
  tubeType?: Maybe<Scalars['String']['output']>;
  /** Warranty of the product. */
  warranty?: Maybe<Scalars['Int']['output']>;
  /** Wattage in Watt */
  wattage?: Maybe<Scalars['Int']['output']>;
  /** Width in mm. */
  width?: Maybe<Scalars['Int']['output']>;
};

/** A `Product` edge in the connection. */
export type ProductEdge = {
  __typename?: 'ProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The `Product` at the end of the edge. */
  node: Product;
};

/** The method to use when ordering `Product`. */
export enum ProductOrderBy {
  /** Order by `id` in *asc* order __(default)__. */
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  /** Order by `id` in *desc* order. */
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ProductThumbnail = {
  __typename?: 'ProductThumbnail';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  urlStandard?: Maybe<Scalars['String']['output']>;
  urlThumbnail?: Maybe<Scalars['String']['output']>;
  urlTiny?: Maybe<Scalars['String']['output']>;
  urlZoom?: Maybe<Scalars['String']['output']>;
};

export type ProductVariant = {
  __typename?: 'ProductVariant';
  binPickingNumber?: Maybe<Scalars['String']['output']>;
  calculatedPrice?: Maybe<Scalars['Float']['output']>;
  calculatedWeight?: Maybe<Scalars['Float']['output']>;
  costPrice?: Maybe<Scalars['Float']['output']>;
  createdAt: Scalars['DateTime']['output'];
  depth?: Maybe<Scalars['Float']['output']>;
  fixedCostShippingPrice?: Maybe<Scalars['Float']['output']>;
  gtin?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Float']['output']>;
  id: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  inventoryLevel?: Maybe<Scalars['Float']['output']>;
  inventoryWarningLevel?: Maybe<Scalars['Float']['output']>;
  isFreeShipping?: Maybe<Scalars['Boolean']['output']>;
  mapPrice?: Maybe<Scalars['Float']['output']>;
  mpn?: Maybe<Scalars['String']['output']>;
  /** Get the `OptionValue`s that this `ProductVariant` is constructed out of. */
  optionValues: Array<OptionValue>;
  price?: Maybe<Scalars['Float']['output']>;
  /** Get this `ProductVariant`'s parent `Product`. */
  product: Product;
  purchasingDisabled?: Maybe<Scalars['Boolean']['output']>;
  purchasingDisabledMessage?: Maybe<Scalars['String']['output']>;
  retailPrice?: Maybe<Scalars['Float']['output']>;
  salePrice?: Maybe<Scalars['Float']['output']>;
  sku?: Maybe<Scalars['String']['output']>;
  skuId?: Maybe<Scalars['Float']['output']>;
  upc?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  weight?: Maybe<Scalars['Float']['output']>;
  width?: Maybe<Scalars['Float']['output']>;
};

export type Query = {
  __typename?: 'Query';
  /** List all `Addresses` */
  addresses: Array<Address>;
  /** Provides a list of the stored AppSettings. */
  appSettings: AppSettings;
  /** Provides the stored default power costs (from AppSettings). */
  appSettingsPowerCost: AppSettings;
  /** Reads and enables pagination through a set of `Category`. */
  categories: CategoryConnection;
  /** Get a single `Category` by id */
  category: Category;
  /** Get a single `Contact` by id */
  contact: Contact;
  /** Get a contact or contacts `Contact` by name */
  contactByName: Array<Contact>;
  /** Reads and enables pagination through a set of `Contact`. */
  contacts: ContactConnection;
  /** Search the KvK registry by KvK number. First tries the `zoeken` API to reduce costs, escalates to the `basisprofiel` if no address is found. Search results are cached in the database for 10080 minutes. */
  detailedKvkSearch: KvkCompany;
  /** Get a single `InvitedUser` (which is a simplified `User`) by `inviteToken`. */
  invitedUser: InvitedUser;
  /** Search the KvK registry by company name or KvK number. Returns the first 10 results. Search results are cached in the database for 10080 minutes. */
  kvkSearch: Array<KvkCompany>;
  /** The signed-in `User` */
  me: User;
  /** Request monthly analytics for a user as an Admin. */
  monthlyAnalytics: Array<MonthlyAnalytics>;
  myMonthlyAnalytics: Array<MonthlyAnalytics>;
  /** Returns the main `Category` that has many subcategories that represent "old" `Product` categories. */
  oldCategory: Category;
  /** Get a single `Product` by id */
  product: Product;
  /** Reads and enables pagination through a set of `Product`. */
  products: ProductConnection;
  /** Get a single `Quotation` by id */
  quotation: Quotation;
  /** Get a single `QuotationRoom` by id */
  quotationRoom: QuotationRoom;
  /** Reads and enables pagination through a set of `Quotation`. */
  quotations: QuotationConnection;
  /** Provides a list of the stored SmartFee */
  smartFees: Array<SmartFee>;
  /** Get the BigCommerce product details sync status. */
  syncStatus: SyncStatus;
  /** Provides a list of the stored TaxBrackets. */
  taxBracket: Array<TaxBracket>;
  /** Get a single `User` by id */
  user: User;
  /** Get a user or users `SearchedUser` by name */
  userByName: Array<SearchedUser>;
  /** Reads and enables pagination through a set of `User`. */
  users: UserConnection;
  /** Validate a `resetPasswordToken` for a `User` by email address. */
  validateResetPasswordToken: Scalars['Boolean']['output'];
};


export type QueryCategoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<CategoryCondition>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<CategoryOrderBy>>;
};


export type QueryCategoryArgs = {
  input: InputCategory;
};


export type QueryContactArgs = {
  input: InputContact;
};


export type QueryContactByNameArgs = {
  input: InputContactByName;
};


export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ContactOrderBy>>;
};


export type QueryDetailedKvkSearchArgs = {
  input: InputDetailedKvkSearch;
};


export type QueryInvitedUserArgs = {
  input: InputInvitedUser;
};


export type QueryKvkSearchArgs = {
  input: InputKvkSearch;
};


export type QueryMonthlyAnalyticsArgs = {
  input: InputMonthlyAnalytics;
};


export type QueryMyMonthlyAnalyticsArgs = {
  input: InputMyMonthlyAnalytics;
};


export type QueryProductArgs = {
  input: InputProduct;
};


export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<ProductOrderBy>>;
};


export type QueryQuotationArgs = {
  input: InputQuotation;
};


export type QueryQuotationRoomArgs = {
  input: InputQuotationRoom;
};


export type QueryQuotationsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<QuotationsCondition>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<QuotationOrderBy>>;
};


export type QueryUserArgs = {
  input: InputUser;
};


export type QueryUserByNameArgs = {
  input: InputUserByName;
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UsersCondition>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserOrderBy>>;
};


export type QueryValidateResetPasswordTokenArgs = {
  input: InputValidateResetPasswordToken;
};

export type Quotation = {
  __typename?: 'Quotation';
  /** The fullName of the `User` that created the `Quotation`. Visible to everyone. */
  accountManagerFullName: Scalars['String']['output'];
  averageOldPowerCost: Scalars['Float']['output'];
  averagePowerCost: Scalars['Float']['output'];
  /** Percentage van de kosten in de 3de termijn. */
  betalingsPercentageDrie: Scalars['Int']['output'];
  /** Percentage van de kosten in de 1ste termijn. */
  betalingsPercentageEen: Scalars['Int']['output'];
  /** Percentage van de kosten in de 2de termijn. */
  betalingsPercentageTwee: Scalars['Int']['output'];
  calculatedCommission: Scalars['Float']['output'];
  calculatedDiscountFixed: Scalars['Float']['output'];
  calculatedDiscountPercentage: Scalars['Float']['output'];
  /** The changes made to this `Quotation`. */
  changelog: Array<QuotationChangelog>;
  /** The commission percentage the account manager will receive when this Quotation is won. */
  commissionPercentage: Scalars['Float']['output'];
  costSavingsOnEnergyPerYear: Scalars['Float']['output'];
  costSavingsOnMaintenancePerYear: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<Contact>;
  description?: Maybe<Scalars['String']['output']>;
  discountFixed?: Maybe<Scalars['Float']['output']>;
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  energyCostPerYear: Scalars['Float']['output'];
  energySavingsPerYear: Scalars['Float']['output'];
  energyUsagePerYear: Scalars['Float']['output'];
  expiresInDays: Scalars['Int']['output'];
  /** Moment the status was changed to `WON` or `LOST` */
  finishedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['ID']['output'];
  internalKostenPlaatsNummer?: Maybe<Scalars['String']['output']>;
  /** Notes on the `Quotation` that are visible in the back-office and not a part of the actual `Quotation`. */
  internalNotes?: Maybe<Scalars['String']['output']>;
  isEditable: Scalars['Boolean']['output'];
  /** Should all added line items be considered `new` and have an `old_product_wattage` of `0`? */
  isNewLocation: Scalars['Boolean']['output'];
  /** The factor between `totalMonthlyLaasCharge` and `totalPrice` for this `Quotation`. (i.e. `totalPrice / laasToPriceFactor = totalMonthlyLaasCharge`). Note tat the `price` of a product variant is provided and the `monthlyLaasCharge` is calculated using this factor. */
  laasToPriceFactor: Scalars['Float']['output'];
  /**
   * Admin `User`s are allowed to view the full details of whom edited the `Quotation` last, AccountManagers can only see this field if they themselves were the last to edit it.
   *
   * Use the `lastEditedByFullName` field to request just the name of the `User`
   */
  lastEditedBy?: Maybe<User>;
  /** The fullName of the `User` that last edited the `Quotation`. Visible to everyone. */
  lastEditedByFullName: Scalars['String']['output'];
  /** Service cost for 1 month. */
  monthlyServiceCost: Scalars['Float']['output'];
  /** Smart fee for 1 month. */
  monthlySmartFee: Scalars['Float']['output'];
  /** The amount in euros that will be charged every month per fixture/ light. Value copied from `smartFee` for audit purposes. */
  monthlySmartFeePerFixture: Scalars['Float']['output'];
  oldEnergyCostPerYear: Scalars['Float']['output'];
  oldEnergyUsagePerYear: Scalars['Float']['output'];
  /** Value returns `null` if the payback time is infinite, i.e. 0 savings. */
  paybackTimeInYears?: Maybe<Scalars['Float']['output']>;
  /** The payment method of the `Quotation` */
  paymentType: PaymentType;
  pdfUrl?: Maybe<Scalars['String']['output']>;
  powerCostExcl?: Maybe<Scalars['Float']['output']>;
  powerCostIncl?: Maybe<Scalars['Float']['output']>;
  /** `Quotation` default for all rooms and line-items, can be overwritten at room and line-item level */
  powerUsageDays: Array<Scalars['CustomBoolean']['output']>;
  /** `Quotation` default for all rooms and line-items, can be overwritten at room and line-item level */
  powerUsageHours: Scalars['Int']['output'];
  projectLocatie?: Maybe<Scalars['String']['output']>;
  quotationDate: Scalars['DateOnly']['output'];
  /** Moment the status was changed to `READY` */
  readyAt?: Maybe<Scalars['DateTime']['output']>;
  reference?: Maybe<Scalars['String']['output']>;
  /** The rooms of the `Quotation`. */
  rooms: Array<QuotationRoom>;
  /** Interval in number of months the monthly_service_cost will be paid in. */
  serviceCostInterval: Scalars['Int']['output'];
  /** DateURL formatted image of the customer's signature */
  signature?: Maybe<Scalars['String']['output']>;
  /** Interval in number of months the monthly_smart_fee will be paid in. */
  smartFeeInterval: Scalars['Int']['output'];
  /** The current status of the `Quotation` */
  status: DealStatus;
  /** The target audience for PDF generation of the `Quotation` */
  targetAudience: TargetAudience;
  totalAccessoryCount: Scalars['Int']['output'];
  totalAssemblyCost: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  totalCostIncl: Scalars['Float']['output'];
  totalCostSavingsPerYear: Scalars['Float']['output'];
  totalCostWithoutDiscount: Scalars['Float']['output'];
  /** Both smart and non-smart fixtures */
  totalFixtureCount: Scalars['Int']['output'];
  totalMonthlyLaasCharge: Scalars['Float']['output'];
  /** Manually overwritten value for the totalMonthlyLaasCharge. Is set to `null` when `paymentType == Full`. */
  totalMonthlyLaasChargeOverwrite?: Maybe<Scalars['Float']['output']>;
  totalNewFixtureCost: Scalars['Float']['output'];
  totalNewFixtureCount: Scalars['Int']['output'];
  totalProductCost: Scalars['Float']['output'];
  totalProductCount: Scalars['Int']['output'];
  totalReplacementFixtureCost: Scalars['Float']['output'];
  totalReplacementFixtureCount: Scalars['Int']['output'];
  totalSavedCO2: Scalars['Float']['output'];
  totalSavedTrees: Scalars['Float']['output'];
  /** Total service cost for the selected interval (in months). */
  totalServiceCost: Scalars['Float']['output'];
  /** Total smart fee for the selected interval (in months). */
  totalSmartFee: Scalars['Float']['output'];
  totalSmartFixtureCount: Scalars['Int']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type QuotationChangelog = {
  __typename?: 'QuotationChangelog';
  change: ChangelogChange;
  finishedAt: Scalars['DateTime']['output'];
  quotation: Scalars['ID']['output'];
  startedAt: Scalars['DateTime']['output'];
  /** The full `User` entity that changed the `Quotation`. Visible to Admins and the `User` who changed the `Quotation`. */
  user?: Maybe<User>;
  /** The fullName of the `User` that changed the `Quotation`. Visible to everyone. */
  userFullName: Scalars['String']['output'];
};

export type QuotationConnection = {
  __typename?: 'QuotationConnection';
  /** A list of edges which contains the `Quotation` and cursor to aid in pagination. */
  edges: Array<QuotationEdge>;
  /** A list of `Quotation` objects. */
  nodes: Array<Quotation>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Quotation` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Quotation` edge in the connection. */
export type QuotationEdge = {
  __typename?: 'QuotationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The `Quotation` at the end of the edge. */
  node: Quotation;
};

export type QuotationLineItem = {
  __typename?: 'QuotationLineItem';
  /** Cost of assembly service per installed unit at the time of adding the line-item to the `Quotation`. */
  assemblyCost: Scalars['Float']['output'];
  ceilingType?: Maybe<Scalars['String']['output']>;
  connectionType?: Maybe<Scalars['String']['output']>;
  /** Total monetary savings for this line item in Euro. */
  costSavingsPerYear: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** Total energy savings for this line item in kWh. */
  energySavingsPerYear: Scalars['Float']['output'];
  /** Choices made in the ProductFilter to get to this `QuotationLineItem`. */
  filterChoices?: Maybe<Scalars['JsonStringified']['output']>;
  id: Scalars['ID']['output'];
  /** Indicate that the lineItem is equipped with a sensor */
  isSensor: Scalars['Boolean']['output'];
  /** Is this line-item a smart light that requires a (monthly) smart fee? */
  isSmart: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  oldProductName: Scalars['String']['output'];
  oldProductQuantity: Scalars['Int']['output'];
  oldProductWattage: Scalars['Float']['output'];
  otherComments?: Maybe<Scalars['String']['output']>;
  outsideSize?: Maybe<Scalars['String']['output']>;
  /** Optional overwrite of the `QuotationRoom` and `Quotation` defaults. */
  powerUsageDays?: Maybe<Array<Scalars['CustomBoolean']['output']>>;
  /** Optional overwrite of the `QuotationRoom` and `Quotation` defaults. */
  powerUsageHours?: Maybe<Scalars['Int']['output']>;
  /** Price per item. */
  price: Scalars['Float']['output'];
  /** Field is optional depending on the grandparent `Quotation` status. When the status is `OPEN` or `READY` there must be a related `Product`. This relation will be removed when a `Product` is cleared when the `Quotation` is marked as `WON` or `WON` and the `Product` is updated or deleted. */
  product?: Maybe<Product>;
  /** Same rules apply as described for the `QuotationLineItem.product` relation. */
  productVariant?: Maybe<ProductVariant>;
  productVariantLabel?: Maybe<Scalars['String']['output']>;
  quantity: Scalars['Int']['output'];
  /** The parent `QuotationRoom`. */
  room: QuotationRoom;
  savedCO2: Scalars['Float']['output'];
  /** Monthly service fee per unit. */
  serviceCost: Scalars['Float']['output'];
  skuNummer?: Maybe<Scalars['String']['output']>;
  totalMonthlyLaasCharge: Scalars['Float']['output'];
  /** Total price for this line item. */
  totalPrice: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  wattage: Scalars['Float']['output'];
};

/** The method to use when ordering `Quotation`. */
export enum QuotationOrderBy {
  /** Order by `id` in *asc* order. */
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  /** Order by `id` in *desc* order. */
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  /** Order by `reference` in *asc* order. */
  ReferenceAsc = 'REFERENCE_ASC',
  /** Order by `reference` in *desc* order. */
  ReferenceDesc = 'REFERENCE_DESC',
  /** Order by `status` in *asc* order. */
  StatusAsc = 'STATUS_ASC',
  /** Order by `status` in *desc* order. */
  StatusDesc = 'STATUS_DESC',
  /** Order by `totalCost` in *asc* order. */
  TotalCostAsc = 'TOTAL_COST_ASC',
  /** Order by `totalCost` in *desc* order. */
  TotalCostDesc = 'TOTAL_COST_DESC',
  /** Order by `updatedAt` in *asc* order. */
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  /** Order by `updatedAt` in *desc* order __(default)__. */
  UpdatedAtDesc = 'UPDATED_AT_DESC'
}

export type QuotationRoom = {
  __typename?: 'QuotationRoom';
  costSavingsPerYear: Scalars['Float']['output'];
  energySavingsPerYear: Scalars['Float']['output'];
  /** Does this room contain a product that qualifies as a sensor? */
  hasSensor: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** The child `QuotationLineItems` of this `QuotationRoom`. */
  lineItems: Array<QuotationLineItem>;
  /** Service cost for 1 month. */
  monthlyServiceCost: Scalars['Float']['output'];
  /** Smart fee for 1 month. */
  monthlySmartFee: Scalars['Float']['output'];
  name: Scalars['String']['output'];
  /** Optional default overwrite for all line-items in this room. Overwrites the Quotation level, but can be overwritten at line-item level. */
  powerUsageDays?: Maybe<Array<Scalars['CustomBoolean']['output']>>;
  /** Quotation default for all rooms and line-items, can be overwritten at room and line-item level */
  powerUsageHours?: Maybe<Scalars['Int']['output']>;
  /** The parent `Quotation` of this `QuotationRoom`. */
  quotation: Quotation;
  roomHeight: Scalars['Float']['output'];
  sensorOnTimePercentage: Scalars['Float']['output'];
  totalAssemblyCost: Scalars['Float']['output'];
  totalCost: Scalars['Float']['output'];
  /** Total service cost for the selected interval (in months). */
  totalServiceCost: Scalars['Float']['output'];
  /** Total smart fee for the selected interval (in months). */
  totalSmartFee: Scalars['Float']['output'];
  verticallyAccessible: Scalars['Boolean']['output'];
};

export type QuotationsCondition = {
  /** Only return `Quotation` where the `Contact` matches this ID. */
  customer?: InputMaybe<Scalars['ID']['input']>;
  status?: InputMaybe<Array<DealStatus>>;
};

/** Simplified User with info available to (other) account managers. */
export type SearchedUser = {
  __typename?: 'SearchedUser';
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
};

export type SendResetPasswordTokenResponse = {
  __typename?: 'SendResetPasswordTokenResponse';
  email: Scalars['String']['output'];
  success: Scalars['Boolean']['output'];
};

export type SmartFee = {
  __typename?: 'SmartFee';
  minQuantity: Scalars['Int']['output'];
  monthlySmartFeePerFixture: Scalars['Float']['output'];
};

export type SyncStatus = {
  __typename?: 'SyncStatus';
  lastSync?: Maybe<Scalars['DateTime']['output']>;
  oldestProduct?: Maybe<Scalars['DateTime']['output']>;
  productCount: Scalars['Int']['output'];
  /** Number of products synced in the last 24 hours. */
  recentlySynced: Scalars['Int']['output'];
  totalSynced: Scalars['Int']['output'];
};

/** The target audiences available for PDF generation of a `Quotation`. */
export enum TargetAudience {
  /** PDF generation for citytech target audience. */
  Citytech = 'CITYTECH',
  /** PDF generation for algemeen target audience. */
  General = 'GENERAL',
  /** PDF generation for sport target audience. */
  Sport = 'SPORT'
}

export type TaxBracket = {
  __typename?: 'TaxBracket';
  cost_per_kwh: Scalars['Float']['output'];
  fixed_cost: Scalars['Float']['output'];
  min_usage_kwh: Scalars['Int']['output'];
};

/** The tiltability of a `Product`. */
export enum Tiltability {
  /** 360 degrees tiltable */
  FullyTiltable = 'FullyTiltable',
  /** Fixed */
  NotTiltable = 'NotTiltable',
  /** 180 degrees tiltable */
  Tiltable = 'Tiltable'
}

export type User = {
  __typename?: 'User';
  /** DataURL of the avatar */
  avatar?: Maybe<Scalars['String']['output']>;
  /** The moment the `User` was blocked, i.e. the `User` is blocked after this `Date`. The User is not blocked before this Date or if this field is `null`. */
  blockedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The Admin `User` who blocked this `User` */
  blockedBy?: Maybe<User>;
  /** The commission percentage this account manager will receive per WON Quotation. */
  commissionPercentage: Scalars['Float']['output'];
  companyNameOwner?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  /** Is the invite still pending? Returns `false` if the `User` finished onboarding. */
  currentStatus: CurrentUserStatus;
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  /** The Admin `User` who (last) invited this `User` */
  invitedBy?: Maybe<User>;
  /** `Contacts` that were last edited by a `User` */
  lastEditedContacts: Array<Contact>;
  /** The moment the last invite email was sent to the `User`. */
  lastInvitedAt?: Maybe<Scalars['DateTime']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  /** Phone numbers of the user, e.g. landline and mobile */
  phoneNumbers?: Maybe<Array<Scalars['String']['output']>>;
  /** The role of the `User` */
  role: UserRole;
  /** The moment the `User` was unblocked. */
  unblockedAt?: Maybe<Scalars['DateTime']['output']>;
  /** The Admin `User` who unblocked this `User` */
  unblockedBy?: Maybe<User>;
  updatedAt: Scalars['DateTime']['output'];
};

export type UserConnection = {
  __typename?: 'UserConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UserEdge>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `User` edge in the connection. */
export type UserEdge = {
  __typename?: 'UserEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['Cursor']['output'];
  /** The `User` at the end of the edge. */
  node: User;
};

/** The method to use when ordering `User`. */
export enum UserOrderBy {
  /** Order by `email` in *asc* order. */
  EmailAsc = 'EMAIL_ASC',
  /** Order by `email` in *desc* order. */
  EmailDesc = 'EMAIL_DESC',
  /** Order by `firstName` in *asc* order. */
  FirstNameAsc = 'FIRST_NAME_ASC',
  /** Order by `firstName` in *desc* order. */
  FirstNameDesc = 'FIRST_NAME_DESC',
  /** Order by `lastName` in *asc* order __(default)__. */
  LastNameAsc = 'LAST_NAME_ASC',
  /** Order by `lastName` in *desc* order. */
  LastNameDesc = 'LAST_NAME_DESC',
  /** Order by `id` in *asc* order. */
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  /** Order by `id` in *desc* order. */
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Role a `User` can have */
export enum UserRole {
  /** A `User` who can create and edit their own `Quotations`. */
  AccountManager = 'ACCOUNT_MANAGER',
  /** A `User` who is essentially an `ACCOUNT_MANAGER`, but can also see and edit the work of other `ADMINs` and `ACCOUNT_MANAGERs`. */
  Admin = 'ADMIN'
}

export type UsersCondition = {
  blockedAt?: InputMaybe<Scalars['NullableDate']['input']>;
  role?: InputMaybe<UserRole>;
};

export type Placeholder = {
  __typename?: 'placeholder';
  id: Scalars['ID']['output'];
};



export type ResolverTypeWrapper<T> = Promise<T> | T;


export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;

export type ResolverFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => Promise<TResult> | TResult;

export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;

export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;

export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}

export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}

export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> =
  | SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs>
  | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;

export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> =
  | ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>)
  | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;

export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (
  parent: TParent,
  context: TContext,
  info: GraphQLResolveInfo
) => Maybe<TTypes> | Promise<Maybe<TTypes>>;

export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;

export type NextResolverFn<T> = () => Promise<T>;

export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (
  next: NextResolverFn<TResult>,
  parent: TParent,
  args: TArgs,
  context: TContext,
  info: GraphQLResolveInfo
) => TResult | Promise<TResult>;



/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = {
  Address: ResolverTypeWrapper<Address>;
  AppSettings: ResolverTypeWrapper<AppSettings>;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Category: ResolverTypeWrapper<Category>;
  CategoryCondition: CategoryCondition;
  CategoryConnection: ResolverTypeWrapper<CategoryConnection>;
  CategoryEdge: ResolverTypeWrapper<CategoryEdge>;
  CategoryOrderBy: CategoryOrderBy;
  ChangelogChange: ChangelogChange;
  Contact: ResolverTypeWrapper<Contact>;
  ContactConnection: ResolverTypeWrapper<ContactConnection>;
  ContactEdge: ResolverTypeWrapper<ContactEdge>;
  ContactOrderBy: ContactOrderBy;
  CurrentUserStatus: CurrentUserStatus;
  Cursor: ResolverTypeWrapper<Scalars['Cursor']['output']>;
  CustomBoolean: ResolverTypeWrapper<Scalars['CustomBoolean']['output']>;
  DateOnly: ResolverTypeWrapper<Scalars['DateOnly']['output']>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  DealStatus: DealStatus;
  DeletedContact: ResolverTypeWrapper<DeletedContact>;
  DeletedQuotation: ResolverTypeWrapper<DeletedQuotation>;
  DeletedQuotationLineItem: ResolverTypeWrapper<DeletedQuotationLineItem>;
  DeletedQuotationRoom: ResolverTypeWrapper<DeletedQuotationRoom>;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  ID: ResolverTypeWrapper<Scalars['ID']['output']>;
  InputAddContactToQuotation: InputAddContactToQuotation;
  InputBlockUser: InputBlockUser;
  InputCategory: InputCategory;
  InputCloneQuotation: InputCloneQuotation;
  InputCloneQuotationLineItem: InputCloneQuotationLineItem;
  InputContact: InputContact;
  InputContactByName: InputContactByName;
  InputCreateContact: InputCreateContact;
  InputCreateContactAddress: InputCreateContactAddress;
  InputCreateQuotation: InputCreateQuotation;
  InputCreateQuotationRoom: InputCreateQuotationRoom;
  InputCreateUser: InputCreateUser;
  InputDeleteContact: InputDeleteContact;
  InputDeleteContactFromQuotation: InputDeleteContactFromQuotation;
  InputDeleteProduct: InputDeleteProduct;
  InputDeleteQuotation: InputDeleteQuotation;
  InputDeleteQuotationLineItem: InputDeleteQuotationLineItem;
  InputDeleteQuotationRoom: InputDeleteQuotationRoom;
  InputDetailedKvkSearch: InputDetailedKvkSearch;
  InputGeneratePdf: InputGeneratePdf;
  InputInvitedUser: InputInvitedUser;
  InputKvkSearch: InputKvkSearch;
  InputMonthlyAnalytics: InputMonthlyAnalytics;
  InputMoveLineItemToRoom: InputMoveLineItemToRoom;
  InputMyMonthlyAnalytics: InputMyMonthlyAnalytics;
  InputProduct: InputProduct;
  InputQuotation: InputQuotation;
  InputQuotationRoom: InputQuotationRoom;
  InputRegisterUser: InputRegisterUser;
  InputResendInvite: InputResendInvite;
  InputResendReadyQuotation: InputResendReadyQuotation;
  InputResetPassword: InputResetPassword;
  InputSendResetPasswordToken: InputSendResetPasswordToken;
  InputSetAppSettings: InputSetAppSettings;
  InputSetAppSettingsSmartFee: InputSetAppSettingsSmartFee;
  InputSetAppSettingsTaxBracket: InputSetAppSettingsTaxBracket;
  InputSetQuotationLineItem: InputSetQuotationLineItem;
  InputTransferQuotation: InputTransferQuotation;
  InputUpdateContact: InputUpdateContact;
  InputUpdateContactAddress: InputUpdateContactAddress;
  InputUpdateProduct: InputUpdateProduct;
  InputUpdateMyPassword: InputUpdateMyPassword;
  InputUpdateMyUser: InputUpdateMyUser;
  InputUpdateQuotationDiscount: InputUpdateQuotationDiscount;
  InputUpdateQuotationInternalNotes: InputUpdateQuotationInternalNotes;
  InputUpdateQuotationIntervals: InputUpdateQuotationIntervals;
  InputUpdateQuotationLaasDiscount: InputUpdateQuotationLaasDiscount;
  InputUpdateQuotationPaymentType: InputUpdateQuotationPaymentType;
  InputUpdateQuotationPowerUsageCost: InputUpdateQuotationPowerUsageCost;
  InputUpdateQuotationProjectLocatieDescription: InputUpdateQuotationProjectLocatieDescription;
  InputUpdateQuotationReferenceDescription: InputUpdateQuotationReferenceDescription;
  InputUpdateQuotationRoom: InputUpdateQuotationRoom;
  InputUpdateQuotationSignature: InputUpdateQuotationSignature;
  InputUpdateQuotationStatus: InputUpdateQuotationStatus;
  InputUpdateQuotationTargetAudience: InputUpdateQuotationTargetAudience;
  InputUpdateUser: InputUpdateUser;
  InputUser: InputUser;
  InputUserByName: InputUserByName;
  InputValidateResetPasswordToken: InputValidateResetPasswordToken;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  InvitedUser: ResolverTypeWrapper<InvitedUser>;
  JsonStringified: ResolverTypeWrapper<Scalars['JsonStringified']['output']>;
  KvkCompany: ResolverTypeWrapper<KvkCompany>;
  MonthlyAnalytics: ResolverTypeWrapper<MonthlyAnalytics>;
  Mutation: ResolverTypeWrapper<{}>;
  NullableDate: ResolverTypeWrapper<Scalars['NullableDate']['output']>;
  NullableInt: ResolverTypeWrapper<Scalars['NullableInt']['output']>;
  OptionValue: ResolverTypeWrapper<OptionValue>;
  PageInfo: ResolverTypeWrapper<PageInfo>;
  PaymentType: PaymentType;
  Product: ResolverTypeWrapper<Product>;
  ProductConnection: ResolverTypeWrapper<ProductConnection>;
  ProductCustomField: ResolverTypeWrapper<ProductCustomField>;
  ProductEdge: ResolverTypeWrapper<ProductEdge>;
  ProductOrderBy: ProductOrderBy;
  ProductThumbnail: ResolverTypeWrapper<ProductThumbnail>;
  ProductVariant: ResolverTypeWrapper<ProductVariant>;
  Query: ResolverTypeWrapper<{}>;
  Quotation: ResolverTypeWrapper<Quotation>;
  QuotationChangelog: ResolverTypeWrapper<QuotationChangelog>;
  QuotationConnection: ResolverTypeWrapper<QuotationConnection>;
  QuotationEdge: ResolverTypeWrapper<QuotationEdge>;
  QuotationLineItem: ResolverTypeWrapper<QuotationLineItem>;
  QuotationOrderBy: QuotationOrderBy;
  QuotationRoom: ResolverTypeWrapper<QuotationRoom>;
  QuotationsCondition: QuotationsCondition;
  SearchedUser: ResolverTypeWrapper<SearchedUser>;
  SendResetPasswordTokenResponse: ResolverTypeWrapper<SendResetPasswordTokenResponse>;
  SmartFee: ResolverTypeWrapper<SmartFee>;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  SyncStatus: ResolverTypeWrapper<SyncStatus>;
  TargetAudience: TargetAudience;
  TaxBracket: ResolverTypeWrapper<TaxBracket>;
  Tiltability: Tiltability;
  User: ResolverTypeWrapper<User>;
  UserConnection: ResolverTypeWrapper<UserConnection>;
  UserEdge: ResolverTypeWrapper<UserEdge>;
  UserOrderBy: UserOrderBy;
  UserRole: UserRole;
  UsersCondition: UsersCondition;
  placeholder: ResolverTypeWrapper<Placeholder>;
};

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = {
  Address: Address;
  AppSettings: AppSettings;
  Boolean: Scalars['Boolean']['output'];
  Category: Category;
  CategoryCondition: CategoryCondition;
  CategoryConnection: CategoryConnection;
  CategoryEdge: CategoryEdge;
  Contact: Contact;
  ContactConnection: ContactConnection;
  ContactEdge: ContactEdge;
  Cursor: Scalars['Cursor']['output'];
  CustomBoolean: Scalars['CustomBoolean']['output'];
  DateOnly: Scalars['DateOnly']['output'];
  DateTime: Scalars['DateTime']['output'];
  DeletedContact: DeletedContact;
  DeletedQuotation: DeletedQuotation;
  DeletedQuotationLineItem: DeletedQuotationLineItem;
  DeletedQuotationRoom: DeletedQuotationRoom;
  Float: Scalars['Float']['output'];
  ID: Scalars['ID']['output'];
  InputAddContactToQuotation: InputAddContactToQuotation;
  InputBlockUser: InputBlockUser;
  InputCategory: InputCategory;
  InputCloneQuotation: InputCloneQuotation;
  InputCloneQuotationLineItem: InputCloneQuotationLineItem;
  InputContact: InputContact;
  InputContactByName: InputContactByName;
  InputCreateContact: InputCreateContact;
  InputCreateContactAddress: InputCreateContactAddress;
  InputCreateQuotation: InputCreateQuotation;
  InputCreateQuotationRoom: InputCreateQuotationRoom;
  InputCreateUser: InputCreateUser;
  InputDeleteContact: InputDeleteContact;
  InputDeleteContactFromQuotation: InputDeleteContactFromQuotation;
  InputDeleteProduct: InputDeleteProduct;
  InputDeleteQuotation: InputDeleteQuotation;
  InputDeleteQuotationLineItem: InputDeleteQuotationLineItem;
  InputDeleteQuotationRoom: InputDeleteQuotationRoom;
  InputDetailedKvkSearch: InputDetailedKvkSearch;
  InputGeneratePdf: InputGeneratePdf;
  InputInvitedUser: InputInvitedUser;
  InputKvkSearch: InputKvkSearch;
  InputMonthlyAnalytics: InputMonthlyAnalytics;
  InputMoveLineItemToRoom: InputMoveLineItemToRoom;
  InputMyMonthlyAnalytics: InputMyMonthlyAnalytics;
  InputProduct: InputProduct;
  InputQuotation: InputQuotation;
  InputQuotationRoom: InputQuotationRoom;
  InputRegisterUser: InputRegisterUser;
  InputResendInvite: InputResendInvite;
  InputResendReadyQuotation: InputResendReadyQuotation;
  InputResetPassword: InputResetPassword;
  InputSendResetPasswordToken: InputSendResetPasswordToken;
  InputSetAppSettings: InputSetAppSettings;
  InputSetAppSettingsSmartFee: InputSetAppSettingsSmartFee;
  InputSetAppSettingsTaxBracket: InputSetAppSettingsTaxBracket;
  InputSetQuotationLineItem: InputSetQuotationLineItem;
  InputTransferQuotation: InputTransferQuotation;
  InputUpdateContact: InputUpdateContact;
  InputUpdateContactAddress: InputUpdateContactAddress;
  InputUpdateProduct: InputUpdateProduct;
  InputUpdateMyPassword: InputUpdateMyPassword;
  InputUpdateMyUser: InputUpdateMyUser;
  InputUpdateQuotationDiscount: InputUpdateQuotationDiscount;
  InputUpdateQuotationInternalNotes: InputUpdateQuotationInternalNotes;
  InputUpdateQuotationIntervals: InputUpdateQuotationIntervals;
  InputUpdateQuotationLaasDiscount: InputUpdateQuotationLaasDiscount;
  InputUpdateQuotationPaymentType: InputUpdateQuotationPaymentType;
  InputUpdateQuotationPowerUsageCost: InputUpdateQuotationPowerUsageCost;
  InputUpdateQuotationProjectLocatieDescription: InputUpdateQuotationProjectLocatieDescription;
  InputUpdateQuotationReferenceDescription: InputUpdateQuotationReferenceDescription;
  InputUpdateQuotationRoom: InputUpdateQuotationRoom;
  InputUpdateQuotationSignature: InputUpdateQuotationSignature;
  InputUpdateQuotationStatus: InputUpdateQuotationStatus;
  InputUpdateQuotationTargetAudience: InputUpdateQuotationTargetAudience;
  InputUpdateUser: InputUpdateUser;
  InputUser: InputUser;
  InputUserByName: InputUserByName;
  InputValidateResetPasswordToken: InputValidateResetPasswordToken;
  Int: Scalars['Int']['output'];
  InvitedUser: InvitedUser;
  JsonStringified: Scalars['JsonStringified']['output'];
  KvkCompany: KvkCompany;
  MonthlyAnalytics: MonthlyAnalytics;
  Mutation: {};
  NullableDate: Scalars['NullableDate']['output'];
  NullableInt: Scalars['NullableInt']['output'];
  OptionValue: OptionValue;
  PageInfo: PageInfo;
  Product: Product;
  ProductConnection: ProductConnection;
  ProductCustomField: ProductCustomField;
  ProductEdge: ProductEdge;
  ProductThumbnail: ProductThumbnail;
  ProductVariant: ProductVariant;
  Query: {};
  Quotation: Quotation;
  QuotationChangelog: QuotationChangelog;
  QuotationConnection: QuotationConnection;
  QuotationEdge: QuotationEdge;
  QuotationLineItem: QuotationLineItem;
  QuotationRoom: QuotationRoom;
  QuotationsCondition: QuotationsCondition;
  SearchedUser: SearchedUser;
  SendResetPasswordTokenResponse: SendResetPasswordTokenResponse;
  SmartFee: SmartFee;
  String: Scalars['String']['output'];
  SyncStatus: SyncStatus;
  TaxBracket: TaxBracket;
  User: User;
  UserConnection: UserConnection;
  UserEdge: UserEdge;
  UsersCondition: UsersCondition;
  placeholder: Placeholder;
};

export type AddressResolvers<ContextType = any, ParentType extends ResolversParentTypes['Address'] = ResolversParentTypes['Address']> = {
  address1?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  address2?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  bigCommerceAddressId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  city?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  company?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  country?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  stateOrProvince?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type AppSettingsResolvers<ContextType = any, ParentType extends ResolversParentTypes['AppSettings'] = ResolversParentTypes['AppSettings']> = {
  defaultLaasFeeToPriceFactor?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  defaultMaintenancePercentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  defaultPowerUsageCostExcl?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  defaultPowerUsageCostIncl?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  defaultPowerUsageDays?: Resolver<Array<ResolversTypes['Boolean']>, ParentType, ContextType>;
  defaultPowerUsageHours?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  defaultSmartFee?: Resolver<Maybe<Array<ResolversTypes['SmartFee']>>, ParentType, ContextType>;
  defaultTaxBracket?: Resolver<Maybe<Array<ResolversTypes['TaxBracket']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  kwHToCO2Factor?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  quotationExpiresInDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  treesSavedToCO2Factor?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Category'] = ResolversParentTypes['Category']> = {
  children?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  defaultProductSort?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isVisible?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  layoutFile?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metaDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metaKeywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  pageTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  parent?: Resolver<Maybe<ResolversTypes['Category']>, ParentType, ContextType>;
  products?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  searchKeywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoryConnection'] = ResolversParentTypes['CategoryConnection']> = {
  edges?: Resolver<Array<ResolversTypes['CategoryEdge']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type CategoryEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['CategoryEdge'] = ResolversParentTypes['CategoryEdge']> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Category'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['Contact'] = ResolversParentTypes['Contact']> = {
  bigCommerceCustomerId?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  billingAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  cocNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  companyName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  customerNumber?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  exactId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  firstName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  hasSameBillingAndShippingAddress?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invoiceEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  jobTitle?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lastName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quotationEmail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  shippingAddress?: Resolver<Maybe<ResolversTypes['Address']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  websites?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactConnection'] = ResolversParentTypes['ContactConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ContactEdge']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Contact']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ContactEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ContactEdge'] = ResolversParentTypes['ContactEdge']> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Contact'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface CursorScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['Cursor'], any> {
  name: 'Cursor';
}

export interface CustomBooleanScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['CustomBoolean'], any> {
  name: 'CustomBoolean';
}

export interface DateOnlyScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateOnly'], any> {
  name: 'DateOnly';
}

export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}

export type DeletedContactResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletedContact'] = ResolversParentTypes['DeletedContact']> = {
  deletedContactId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedQuotationResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletedQuotation'] = ResolversParentTypes['DeletedQuotation']> = {
  deletedQuotationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedQuotationLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletedQuotationLineItem'] = ResolversParentTypes['DeletedQuotationLineItem']> = {
  deletedQuotationLineItemId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quotationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  roomId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type DeletedQuotationRoomResolvers<ContextType = any, ParentType extends ResolversParentTypes['DeletedQuotationRoom'] = ResolversParentTypes['DeletedQuotationRoom']> = {
  deletedQuotationRoomId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  quotationId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type InvitedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['InvitedUser'] = ResolversParentTypes['InvitedUser']> = {
  companyNameOwner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitedByFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRole'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export interface JsonStringifiedScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JsonStringified'], any> {
  name: 'JsonStringified';
}

export type KvkCompanyResolvers<ContextType = any, ParentType extends ResolversParentTypes['KvkCompany'] = ResolversParentTypes['KvkCompany']> = {
  establishmentNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  formerName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  houseNumber?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  houseNumberAddition?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  kvkNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  place?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rsin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  streetName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  tradeName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  type?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MonthlyAnalyticsResolvers<ContextType = any, ParentType extends ResolversParentTypes['MonthlyAnalytics'] = ResolversParentTypes['MonthlyAnalytics']> = {
  accessoryCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  averageLostLeadTimeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  averageWonLeadTimeDays?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  commission?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  energyRanking?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  energySavingsPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fixtureCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lineItemCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  lostCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  month?: Resolver<ResolversTypes['DateOnly'], ParentType, ContextType>;
  monthlySmartFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  openCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  productCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  readyCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  roomCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAssemblyCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  usersWithSalesCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  wonCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type MutationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = {
  UpdateQuotationSignature?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationSignatureArgs, 'input'>>;
  addContactToQuotation?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationAddContactToQuotationArgs, 'input'>>;
  blockUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationBlockUserArgs, 'input'>>;
  cloneQuotation?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationCloneQuotationArgs, 'input'>>;
  cloneQuotationLineItem?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationCloneQuotationLineItemArgs, 'input'>>;
  createContact?: Resolver<ResolversTypes['Contact'], ParentType, ContextType, RequireFields<MutationCreateContactArgs, 'input'>>;
  createQuotation?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationCreateQuotationArgs, 'input'>>;
  createQuotationRoom?: Resolver<ResolversTypes['QuotationRoom'], ParentType, ContextType, RequireFields<MutationCreateQuotationRoomArgs, 'input'>>;
  createUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'input'>>;
  deleteContact?: Resolver<ResolversTypes['DeletedContact'], ParentType, ContextType, RequireFields<MutationDeleteContactArgs, 'input'>>;
  deleteContactFromQuotation?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationDeleteContactFromQuotationArgs, 'input'>>;
  deleteQuotation?: Resolver<ResolversTypes['DeletedQuotation'], ParentType, ContextType, RequireFields<MutationDeleteQuotationArgs, 'input'>>;
  deleteQuotationLineItem?: Resolver<ResolversTypes['DeletedQuotationLineItem'], ParentType, ContextType, RequireFields<MutationDeleteQuotationLineItemArgs, 'input'>>;
  deleteQuotationRoom?: Resolver<ResolversTypes['DeletedQuotationRoom'], ParentType, ContextType, RequireFields<MutationDeleteQuotationRoomArgs, 'input'>>;
  generatePdf?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationGeneratePdfArgs, 'input'>>;
  moveLineItemToRoom?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationMoveLineItemToRoomArgs, 'input'>>;
  registerUser?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType, RequireFields<MutationRegisterUserArgs, 'input'>>;
  resendInvite?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationResendInviteArgs, 'input'>>;
  resendReadyQuotation?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationResendReadyQuotationArgs, 'input'>>;
  resetPassword?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationResetPasswordArgs, 'input'>>;
  sendResetPasswordToken?: Resolver<ResolversTypes['SendResetPasswordTokenResponse'], ParentType, ContextType, RequireFields<MutationSendResetPasswordTokenArgs, 'input'>>;
  setAppSettings?: Resolver<ResolversTypes['AppSettings'], ParentType, ContextType, RequireFields<MutationSetAppSettingsArgs, 'input'>>;
  setQuotationLineItem?: Resolver<ResolversTypes['QuotationLineItem'], ParentType, ContextType, RequireFields<MutationSetQuotationLineItemArgs, 'input'>>;
  transferQuotation?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationTransferQuotationArgs, 'input'>>;
  updateContact?: Resolver<ResolversTypes['Contact'], ParentType, ContextType, RequireFields<MutationUpdateContactArgs, 'input'>>;
  updateMyPassword?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateMyPasswordArgs, 'input'>>;
  updateMyUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateMyUserArgs, 'input'>>;
  updateProduct?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<MutationUpdateProductArgs, 'input'>>;
  updateQuotationDiscount?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationDiscountArgs, 'input'>>;
  updateQuotationInternalNotes?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationInternalNotesArgs, 'input'>>;
  updateQuotationIntervals?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationIntervalsArgs, 'input'>>;
  updateQuotationLaasDiscount?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationLaasDiscountArgs, 'input'>>;
  updateQuotationPaymentType?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationPaymentTypeArgs, 'input'>>;
  updateQuotationPowerUsageCost?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationPowerUsageCostArgs, 'input'>>;
  updateQuotationProjectLocatie?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationProjectLocatieArgs, 'input'>>;
  updateQuotationReferenceAndDescription?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationReferenceAndDescriptionArgs, 'input'>>;
  updateQuotationRoom?: Resolver<ResolversTypes['QuotationRoom'], ParentType, ContextType, RequireFields<MutationUpdateQuotationRoomArgs, 'input'>>;
  updateQuotationStatus?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationStatusArgs, 'input'>>;
  updateQuotationTargetAudience?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<MutationUpdateQuotationTargetAudienceArgs, 'input'>>;
  updateUser?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'input'>>;
};

export interface NullableDateScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NullableDate'], any> {
  name: 'NullableDate';
}

export interface NullableIntScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['NullableInt'], any> {
  name: 'NullableInt';
}

export type OptionValueResolvers<ContextType = any, ParentType extends ResolversParentTypes['OptionValue'] = ResolversParentTypes['OptionValue']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  label?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionDisplayName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionId?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PageInfoResolvers<ContextType = any, ParentType extends ResolversParentTypes['PageInfo'] = ResolversParentTypes['PageInfo']> = {
  endCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasNextPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  hasPreviousPage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  startCursor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductResolvers<ContextType = any, ParentType extends ResolversParentTypes['Product'] = ResolversParentTypes['Product']> = {
  availability?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  availabilityDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  baseVariant?: Resolver<Maybe<ResolversTypes['ProductVariant']>, ParentType, ContextType>;
  binPickingNumber?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  brandId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  calculatedPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  categories?: Resolver<Array<ResolversTypes['Category']>, ParentType, ContextType>;
  condition?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  costPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  customFields?: Resolver<ResolversTypes['ProductCustomField'], ParentType, ContextType>;
  dateCreated?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  dateModified?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  depth?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  description?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  fixedCostShippingPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  gtin?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  height?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  inventoryLevel?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  inventoryTracking?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  inventoryWarningLevel?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  isConditionShown?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFeatured?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isFreeShipping?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPreorderOnly?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isPriceHidden?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSensor?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isVisible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  lastSyncedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  layoutFile?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  mapPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  metaDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  metaKeywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  mpn?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  openGraphDescription?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  openGraphTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  openGraphUseImage?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  openGraphUseMetaDescription?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  openGraphUseProductName?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  orderQuantityMaximum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  orderQuantityMinimum?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  pageTitle?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preorderMessage?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  preorderReleaseDate?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  priceHiddenLabel?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  productTaxCode?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  relatedProducts?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  retailPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  salePrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  searchKeywords?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  sku?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  sortOrder?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  taxClassId?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  thumbnail?: Resolver<Maybe<ResolversTypes['ProductThumbnail']>, ParentType, ContextType>;
  type?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  upc?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  variants?: Resolver<Array<ResolversTypes['ProductVariant']>, ParentType, ContextType>;
  warranty?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  weight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  width?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductConnection'] = ResolversParentTypes['ProductConnection']> = {
  edges?: Resolver<Array<ResolversTypes['ProductEdge']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Product']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductCustomFieldResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductCustomField'] = ResolversParentTypes['ProductCustomField']> = {
  assemblyCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  beamAngle?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  beamAngleText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  diameter?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  fixtureColor?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  hasStarter?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  isEligibleForEia?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  length?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lightIntensity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  lightIntensityText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  retrofit?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  retrofitQuantity?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  series?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  serviceCost?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  socket?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  suspensionHeights?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  tiltability?: Resolver<Maybe<ResolversTypes['Tiltability']>, ParentType, ContextType>;
  tubeType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  warranty?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  wattage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductEdge'] = ResolversParentTypes['ProductEdge']> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductThumbnailResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductThumbnail'] = ResolversParentTypes['ProductThumbnail']> = {
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  urlStandard?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  urlThumbnail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  urlTiny?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  urlZoom?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type ProductVariantResolvers<ContextType = any, ParentType extends ResolversParentTypes['ProductVariant'] = ResolversParentTypes['ProductVariant']> = {
  binPickingNumber?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  calculatedPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  calculatedWeight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  costPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  depth?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  fixedCostShippingPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  gtin?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  height?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  inventoryLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  inventoryWarningLevel?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  isFreeShipping?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  mapPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  mpn?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  optionValues?: Resolver<Array<ResolversTypes['OptionValue']>, ParentType, ContextType>;
  price?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType>;
  purchasingDisabled?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  purchasingDisabledMessage?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  retailPrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  salePrice?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  sku?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  skuId?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  upc?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  weight?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  width?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QueryResolvers<ContextType = any, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = {
  addresses?: Resolver<Array<ResolversTypes['Address']>, ParentType, ContextType>;
  appSettings?: Resolver<ResolversTypes['AppSettings'], ParentType, ContextType>;
  appSettingsPowerCost?: Resolver<ResolversTypes['AppSettings'], ParentType, ContextType>;
  categories?: Resolver<ResolversTypes['CategoryConnection'], ParentType, ContextType, RequireFields<QueryCategoriesArgs, 'orderBy'>>;
  category?: Resolver<ResolversTypes['Category'], ParentType, ContextType, RequireFields<QueryCategoryArgs, 'input'>>;
  contact?: Resolver<ResolversTypes['Contact'], ParentType, ContextType, RequireFields<QueryContactArgs, 'input'>>;
  contactByName?: Resolver<Array<ResolversTypes['Contact']>, ParentType, ContextType, RequireFields<QueryContactByNameArgs, 'input'>>;
  contacts?: Resolver<ResolversTypes['ContactConnection'], ParentType, ContextType, RequireFields<QueryContactsArgs, 'orderBy'>>;
  detailedKvkSearch?: Resolver<ResolversTypes['KvkCompany'], ParentType, ContextType, RequireFields<QueryDetailedKvkSearchArgs, 'input'>>;
  invitedUser?: Resolver<ResolversTypes['InvitedUser'], ParentType, ContextType, RequireFields<QueryInvitedUserArgs, 'input'>>;
  kvkSearch?: Resolver<Array<ResolversTypes['KvkCompany']>, ParentType, ContextType, RequireFields<QueryKvkSearchArgs, 'input'>>;
  me?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  monthlyAnalytics?: Resolver<Array<ResolversTypes['MonthlyAnalytics']>, ParentType, ContextType, RequireFields<QueryMonthlyAnalyticsArgs, 'input'>>;
  myMonthlyAnalytics?: Resolver<Array<ResolversTypes['MonthlyAnalytics']>, ParentType, ContextType, RequireFields<QueryMyMonthlyAnalyticsArgs, 'input'>>;
  oldCategory?: Resolver<ResolversTypes['Category'], ParentType, ContextType>;
  product?: Resolver<ResolversTypes['Product'], ParentType, ContextType, RequireFields<QueryProductArgs, 'input'>>;
  products?: Resolver<ResolversTypes['ProductConnection'], ParentType, ContextType, RequireFields<QueryProductsArgs, 'orderBy'>>;
  quotation?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType, RequireFields<QueryQuotationArgs, 'input'>>;
  quotationRoom?: Resolver<ResolversTypes['QuotationRoom'], ParentType, ContextType, RequireFields<QueryQuotationRoomArgs, 'input'>>;
  quotations?: Resolver<ResolversTypes['QuotationConnection'], ParentType, ContextType, RequireFields<QueryQuotationsArgs, 'orderBy'>>;
  smartFees?: Resolver<Array<ResolversTypes['SmartFee']>, ParentType, ContextType>;
  syncStatus?: Resolver<ResolversTypes['SyncStatus'], ParentType, ContextType>;
  taxBracket?: Resolver<Array<ResolversTypes['TaxBracket']>, ParentType, ContextType>;
  user?: Resolver<ResolversTypes['User'], ParentType, ContextType, RequireFields<QueryUserArgs, 'input'>>;
  userByName?: Resolver<Array<ResolversTypes['SearchedUser']>, ParentType, ContextType, RequireFields<QueryUserByNameArgs, 'input'>>;
  users?: Resolver<ResolversTypes['UserConnection'], ParentType, ContextType, RequireFields<QueryUsersArgs, 'orderBy'>>;
  validateResetPasswordToken?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType, RequireFields<QueryValidateResetPasswordTokenArgs, 'input'>>;
};

export type QuotationResolvers<ContextType = any, ParentType extends ResolversParentTypes['Quotation'] = ResolversParentTypes['Quotation']> = {
  accountManagerFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  averageOldPowerCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  averagePowerCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  betalingsPercentageDrie?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  betalingsPercentageEen?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  betalingsPercentageTwee?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  calculatedCommission?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  calculatedDiscountFixed?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  calculatedDiscountPercentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  changelog?: Resolver<Array<ResolversTypes['QuotationChangelog']>, ParentType, ContextType>;
  commissionPercentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  costSavingsOnEnergyPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  costSavingsOnMaintenancePerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  customer?: Resolver<Maybe<ResolversTypes['Contact']>, ParentType, ContextType>;
  description?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  discountFixed?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  discountPercentage?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  energyCostPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  energySavingsPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  energyUsagePerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  expiresInDays?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  finishedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  internalKostenPlaatsNummer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  internalNotes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  isEditable?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isNewLocation?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  laasToPriceFactor?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  lastEditedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  lastEditedByFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  monthlyServiceCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  monthlySmartFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  monthlySmartFeePerFixture?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  oldEnergyCostPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  oldEnergyUsagePerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  paybackTimeInYears?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  paymentType?: Resolver<ResolversTypes['PaymentType'], ParentType, ContextType>;
  pdfUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerCostExcl?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  powerCostIncl?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  powerUsageDays?: Resolver<Array<ResolversTypes['CustomBoolean']>, ParentType, ContextType>;
  powerUsageHours?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  projectLocatie?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quotationDate?: Resolver<ResolversTypes['DateOnly'], ParentType, ContextType>;
  readyAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  reference?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  rooms?: Resolver<Array<ResolversTypes['QuotationRoom']>, ParentType, ContextType>;
  serviceCostInterval?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  signature?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  smartFeeInterval?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  status?: Resolver<ResolversTypes['DealStatus'], ParentType, ContextType>;
  targetAudience?: Resolver<ResolversTypes['TargetAudience'], ParentType, ContextType>;
  totalAccessoryCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalAssemblyCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCostIncl?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCostSavingsPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCostWithoutDiscount?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalFixtureCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalMonthlyLaasCharge?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalMonthlyLaasChargeOverwrite?: Resolver<Maybe<ResolversTypes['Float']>, ParentType, ContextType>;
  totalNewFixtureCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalNewFixtureCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalProductCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalProductCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalReplacementFixtureCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalReplacementFixtureCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalSavedCO2?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalSavedTrees?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalServiceCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalSmartFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalSmartFixtureCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuotationChangelogResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuotationChangelog'] = ResolversParentTypes['QuotationChangelog']> = {
  change?: Resolver<ResolversTypes['ChangelogChange'], ParentType, ContextType>;
  finishedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  quotation?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  startedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userFullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuotationConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuotationConnection'] = ResolversParentTypes['QuotationConnection']> = {
  edges?: Resolver<Array<ResolversTypes['QuotationEdge']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['Quotation']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuotationEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuotationEdge'] = ResolversParentTypes['QuotationEdge']> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuotationLineItemResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuotationLineItem'] = ResolversParentTypes['QuotationLineItem']> = {
  assemblyCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  ceilingType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  connectionType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  costSavingsPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  energySavingsPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  filterChoices?: Resolver<Maybe<ResolversTypes['JsonStringified']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  isSensor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  isSmart?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  oldProductName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  oldProductQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  oldProductWattage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  otherComments?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  outsideSize?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  powerUsageDays?: Resolver<Maybe<Array<ResolversTypes['CustomBoolean']>>, ParentType, ContextType>;
  powerUsageHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  price?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  product?: Resolver<Maybe<ResolversTypes['Product']>, ParentType, ContextType>;
  productVariant?: Resolver<Maybe<ResolversTypes['ProductVariant']>, ParentType, ContextType>;
  productVariantLabel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  quantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  room?: Resolver<ResolversTypes['QuotationRoom'], ParentType, ContextType>;
  savedCO2?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  serviceCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  skuNummer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  totalMonthlyLaasCharge?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalPrice?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  wattage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type QuotationRoomResolvers<ContextType = any, ParentType extends ResolversParentTypes['QuotationRoom'] = ResolversParentTypes['QuotationRoom']> = {
  costSavingsPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  energySavingsPerYear?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  hasSensor?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  imageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  lineItems?: Resolver<Array<ResolversTypes['QuotationLineItem']>, ParentType, ContextType>;
  monthlyServiceCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  monthlySmartFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  powerUsageDays?: Resolver<Maybe<Array<ResolversTypes['CustomBoolean']>>, ParentType, ContextType>;
  powerUsageHours?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  quotation?: Resolver<ResolversTypes['Quotation'], ParentType, ContextType>;
  roomHeight?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  sensorOnTimePercentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalAssemblyCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalServiceCost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  totalSmartFee?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  verticallyAccessible?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SearchedUserResolvers<ContextType = any, ParentType extends ResolversParentTypes['SearchedUser'] = ResolversParentTypes['SearchedUser']> = {
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SendResetPasswordTokenResponseResolvers<ContextType = any, ParentType extends ResolversParentTypes['SendResetPasswordTokenResponse'] = ResolversParentTypes['SendResetPasswordTokenResponse']> = {
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  success?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SmartFeeResolvers<ContextType = any, ParentType extends ResolversParentTypes['SmartFee'] = ResolversParentTypes['SmartFee']> = {
  minQuantity?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  monthlySmartFeePerFixture?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type SyncStatusResolvers<ContextType = any, ParentType extends ResolversParentTypes['SyncStatus'] = ResolversParentTypes['SyncStatus']> = {
  lastSync?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  oldestProduct?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  productCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  recentlySynced?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  totalSynced?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type TaxBracketResolvers<ContextType = any, ParentType extends ResolversParentTypes['TaxBracket'] = ResolversParentTypes['TaxBracket']> = {
  cost_per_kwh?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  fixed_cost?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  min_usage_kwh?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserResolvers<ContextType = any, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = {
  avatar?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  blockedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  blockedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  commissionPercentage?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  companyNameOwner?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  currentStatus?: Resolver<ResolversTypes['CurrentUserStatus'], ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  firstName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  fullName?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  invitedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  lastEditedContacts?: Resolver<Array<ResolversTypes['Contact']>, ParentType, ContextType>;
  lastInvitedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  lastName?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phoneNumbers?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['UserRole'], ParentType, ContextType>;
  unblockedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  unblockedBy?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  updatedAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserConnectionResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserConnection'] = ResolversParentTypes['UserConnection']> = {
  edges?: Resolver<Array<ResolversTypes['UserEdge']>, ParentType, ContextType>;
  nodes?: Resolver<Array<ResolversTypes['User']>, ParentType, ContextType>;
  pageInfo?: Resolver<ResolversTypes['PageInfo'], ParentType, ContextType>;
  totalCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type UserEdgeResolvers<ContextType = any, ParentType extends ResolversParentTypes['UserEdge'] = ResolversParentTypes['UserEdge']> = {
  cursor?: Resolver<ResolversTypes['Cursor'], ParentType, ContextType>;
  node?: Resolver<ResolversTypes['User'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type PlaceholderResolvers<ContextType = any, ParentType extends ResolversParentTypes['placeholder'] = ResolversParentTypes['placeholder']> = {
  id?: Resolver<ResolversTypes['ID'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
};

export type Resolvers<ContextType = any> = {
  Address?: AddressResolvers<ContextType>;
  AppSettings?: AppSettingsResolvers<ContextType>;
  Category?: CategoryResolvers<ContextType>;
  CategoryConnection?: CategoryConnectionResolvers<ContextType>;
  CategoryEdge?: CategoryEdgeResolvers<ContextType>;
  Contact?: ContactResolvers<ContextType>;
  ContactConnection?: ContactConnectionResolvers<ContextType>;
  ContactEdge?: ContactEdgeResolvers<ContextType>;
  Cursor?: GraphQLScalarType;
  CustomBoolean?: GraphQLScalarType;
  DateOnly?: GraphQLScalarType;
  DateTime?: GraphQLScalarType;
  DeletedContact?: DeletedContactResolvers<ContextType>;
  DeletedQuotation?: DeletedQuotationResolvers<ContextType>;
  DeletedQuotationLineItem?: DeletedQuotationLineItemResolvers<ContextType>;
  DeletedQuotationRoom?: DeletedQuotationRoomResolvers<ContextType>;
  InvitedUser?: InvitedUserResolvers<ContextType>;
  JsonStringified?: GraphQLScalarType;
  KvkCompany?: KvkCompanyResolvers<ContextType>;
  MonthlyAnalytics?: MonthlyAnalyticsResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NullableDate?: GraphQLScalarType;
  NullableInt?: GraphQLScalarType;
  OptionValue?: OptionValueResolvers<ContextType>;
  PageInfo?: PageInfoResolvers<ContextType>;
  Product?: ProductResolvers<ContextType>;
  ProductConnection?: ProductConnectionResolvers<ContextType>;
  ProductCustomField?: ProductCustomFieldResolvers<ContextType>;
  ProductEdge?: ProductEdgeResolvers<ContextType>;
  ProductThumbnail?: ProductThumbnailResolvers<ContextType>;
  ProductVariant?: ProductVariantResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  Quotation?: QuotationResolvers<ContextType>;
  QuotationChangelog?: QuotationChangelogResolvers<ContextType>;
  QuotationConnection?: QuotationConnectionResolvers<ContextType>;
  QuotationEdge?: QuotationEdgeResolvers<ContextType>;
  QuotationLineItem?: QuotationLineItemResolvers<ContextType>;
  QuotationRoom?: QuotationRoomResolvers<ContextType>;
  SearchedUser?: SearchedUserResolvers<ContextType>;
  SendResetPasswordTokenResponse?: SendResetPasswordTokenResponseResolvers<ContextType>;
  SmartFee?: SmartFeeResolvers<ContextType>;
  SyncStatus?: SyncStatusResolvers<ContextType>;
  TaxBracket?: TaxBracketResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserConnection?: UserConnectionResolvers<ContextType>;
  UserEdge?: UserEdgeResolvers<ContextType>;
  placeholder?: PlaceholderResolvers<ContextType>;
};

